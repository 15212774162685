import { filter, map } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useSchoolNewsStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addNewsStatus: STATUS.NOT_STARTED,
            updateNewsStatus: STATUS.NOT_STARTED,
            deleteNewsStatus: STATUS.NOT_STARTED,
        })
    },

    getNewsAction: async (payload) => {
        set({ getNewsStatus: STATUS.FETCHING })
        const response = await apis.getNewsApi(payload)
        if (response.ok) {
            set({ schoolNewsData: response.data, getNewsStatus: STATUS.SUCCESS })
        }
        else {
            set({ getNewsStatus: STATUS.FAILED })
        }
    },

    addNewsAction: async (payload) => {
        set({ addNewsStatus: STATUS.FETCHING })
        const response = await apis.addNewsApi(payload)
        const schoolNewsData = get().schoolNewsData || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ schoolNewsData: ([...schoolNewsData, (response.data)]), addNewsStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addNewsStatus: STATUS.FAILED })
        }
    },

    updateNewsAction: async (payload) => {
        set({ updateNewsStatus: STATUS.FETCHING })
        const response = await apis.updateNewsApi(payload)
        const schoolNewsData = get().schoolNewsData || []
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ schoolNewsData: map(schoolNewsData, d => response.data._id === d._id ? response.data : d), updateNewsStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateNewsStatus: STATUS.FAILED })
        }
    },

    deleteNewsAction: async (payload) => {
        set({ deleteNewsStatus: STATUS.FETCHING });
        const { ok } = await apis.deleteNewsApi(payload)
        if (ok) {
            SuccessAlert('Deleted Successfully')
            let schoolNewsData = get().schoolNewsData;
            schoolNewsData = filter(schoolNewsData, d => d._id !== payload.id);
            set({ schoolNewsData: schoolNewsData, deleteNewsStatus: STATUS.SUCCESS, })
        }
        else {
            ErrorAlert();
            set({ deleteNewsStatus: STATUS.FAILED })
        }
    },
}))
