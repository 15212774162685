
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useSchoolFacilityStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addSchoolFacilityStatus: STATUS.NOT_STARTED,
            updateSchoolFacilityStatus: STATUS.NOT_STARTED,
            deleteSchoolFacilityStatus: STATUS.NOT_STARTED,
        })
    },

    addSchoolFacilityAction: async (payload) => {
        set({ addSchoolFacilityStatus: STATUS.FETCHING })
        const response = await apis.addFacilityApi(payload)
        const previousFacilityData = get().facility || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ facility: ([...previousFacilityData, (response.data)]), addSchoolFacilityStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addSchoolFacilityStatus: STATUS.FAILED })
        }
    },

    updateSchoolFacilityAction: async (payload) => {
        set({ updateSchoolFacilityStatus: STATUS.FETCHING })
        const response = await apis.updateFacilityApi(payload)
        const previosData = get().facility
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ facility: (response.data._id === previosData._id) ? response.data : previosData, updateSchoolFacilityStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateSchoolFacilityStatus: STATUS.FAILED })
        }
    },
    getSchoolFacilityAction: async (payload) => {
        set({ getSchoolFacilityStatus: STATUS.FETCHING })
        const response = await apis.getFacilityApi(payload)
        if (response.ok) {
            set({ facility: response.data, getSchoolFacilityStatus: STATUS.SUCCESS })
        }
        else {
            set({ getSchoolFacilityStatus: STATUS.FAILED })
        }
    },

    // deleteSchoolFacilityAction: async (payload) => {
    //     set({ deleteSchoolFacilityStatus: STATUS.FETCHING });
    //     const { ok } = await apis.deleteSchoolFacilityApi(payload)
    //     if (ok) {
    //         SuccessAlert('Deleted Successfully')
    //         let facility = get().facility;
    //         facility = filter(facility, d => d._id !== payload.id);
    //         set({ companyData: facility, deleteSchoolFacilityStatus: STATUS.SUCCESS, })
    //     }
    //     else {
    //         ErrorAlert();
    //         set({ facility: [], deleteSchoolFacilityStatus: STATUS.FAILED })
    //     }
    // },

}))