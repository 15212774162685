import { filter, map } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useSchoolEventStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addEventStatus: STATUS.NOT_STARTED,
            updateEventStatus: STATUS.NOT_STARTED,
            deleteEventStatus: STATUS.NOT_STARTED,
        })
    },

    getEventAction: async (payload) => {
        set({ getEventStatus: STATUS.FETCHING })
        const response = await apis.getEventApi(payload)
        if (response.ok) {
            set({ schoolEventData: response.data, getEventStatus: STATUS.SUCCESS })
        }
        else {
            set({ getEventStatus: STATUS.FAILED })
        }
    },

    addEventAction: async (payload) => {
        set({ addEventStatus: STATUS.FETCHING })
        const response = await apis.addEventApi(payload)
        const schoolEventData = get().schoolEventData || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ schoolEventData: ([...schoolEventData, (response.data)]), addEventStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addEventStatus: STATUS.FAILED })
        }
    },

    updateEventAction: async (payload) => {
        set({ updateEventStatus: STATUS.FETCHING })
        const response = await apis.updateEventApi(payload)
        const schoolEventData = get().schoolEventData || []
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ schoolEventData: map(schoolEventData, d => response.data._id === d._id ? response.data : d), updateEventStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateEventStatus: STATUS.FAILED })
        }
    },

    deleteEventAction: async (payload) => {
        set({ deleteEventStatus: STATUS.FETCHING });
        const { ok } = await apis.deleteEventApi(payload)
        if (ok) {
            SuccessAlert('Deleted Successfully')
            let schoolEventData = get().schoolEventData;
            schoolEventData = filter(schoolEventData, d => d._id !== payload.id);
            set({ schoolEventData: schoolEventData, deleteEventStatus: STATUS.SUCCESS, })
        }
        else {
            ErrorAlert();
            set({ deleteEventStatus: STATUS.FAILED })
        }
    },
}))
