import { useEffect, useMemo } from "react"
import { Box, Button, Center, Flex, FormControl, FormLabel, Textarea } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { find } from "lodash"
import { useSchoolFacilityStore } from "../../store/facility"
import { SchoolId } from "../../config.js"
import { STATUS } from "../../constants"
import MainLayout from "../../app/layouts/MainLayout"
import PageHeading from "../../components/PageHeading"

export const Facility = ({ facilityType, heading, name }) => {

    const { addSchoolFacilityAction, addSchoolFacilityStatus, updateSchoolFacilityAction, updateSchoolFacilityStatus, resetStatus, facility } = useSchoolFacilityStore(s => ({
        addSchoolFacilityAction: s.addSchoolFacilityAction,
        addSchoolFacilityStatus: s.addSchoolFacilityStatus,
        updateSchoolFacilityAction: s.updateSchoolFacilityAction,
        updateSchoolFacilityStatus: s.updateSchoolFacilityStatus,
        resetStatus: s.resetStatus,
        facility: s.facility,
    }))

    const singleFacility = useMemo(() => find(facility, f => f.title === facilityType), [facility, facilityType])
    const { control, setValue, handleSubmit, watch } = useForm({
        defaultValue: {
            description: singleFacility?.description
        }
    })

    const desc = watch('description')
    const onSubmit = (data) => {
        const { description } = data
        const facilityData = {
            schoolId: SchoolId,
            title: facilityType,
            description: description,
        }
        if (singleFacility) {
            facilityData['id'] = singleFacility._id;
            updateSchoolFacilityAction(facilityData)
        }
        else {
            addSchoolFacilityAction(facilityData)
        }
    }

    useEffect(() => {
        setValue('description', singleFacility?.description || '')
    }, [singleFacility, setValue, name])


    useEffect(() => {
        if ((addSchoolFacilityAction === STATUS.SUCCESS) || (updateSchoolFacilityStatus === STATUS.SUCCESS)) {
            resetStatus()
        }
    }, [addSchoolFacilityAction, resetStatus, updateSchoolFacilityStatus])

    return (
        <MainLayout>
            <PageHeading heading={heading} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Center>
                    <Box borderRadius='3px' border='1px solid' borderColor='#ccc' p={3} w='600px'>
                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Controller
                                control={control}
                                name='description'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='Description' size='sm' />
                                }
                            />
                        </FormControl>
                        <Flex justify='flex-end' my={4}>
                            <Button
                                isLoading={(updateSchoolFacilityStatus === STATUS.FETCHING) || (addSchoolFacilityStatus === STATUS.FETCHING)}
                                type='submit'
                                color='white'
                                bg='#bd0745'
                                isDisabled={!desc}
                                _hover={{ bg: '#a3053b' }}
                                size='sm'>
                                {`${singleFacility ? 'Update' : 'Add'}`}
                            </Button>
                        </Flex>
                    </Box>
                </Center>
            </form>
        </MainLayout>
    )
}