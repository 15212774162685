import { useEffect, useState } from "react"
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react"

import { useSchoolNewsStore } from "../../store/news"

import { Controller, useForm } from "react-hook-form"

import { STATUS } from "../../constants"

import { find } from "lodash"
import { useSchoolInformationStore } from "../../store/personalInformation"
import { UploadImage } from "../../components/UploadImage"
import { SchoolId } from "../../config.js"

export const NewsDrawer = ({ isOpen, onClose, newsId }) => {
    const { control, handleSubmit, setValue, reset } = useForm()
    const [imageUrl, setImageUrl] = useState()
   
    const { addNewsStatus, addNewsAction, updateNewsAction, updateNewsStatus, resetStatus, schoolNewsData } = useSchoolNewsStore(s => ({
        addNewsAction: s.addNewsAction,
        addNewsStatus: s.addNewsStatus,
        updateNewsAction: s.updateNewsAction,
        updateNewsStatus: s.updateNewsStatus,
        schoolNewsData: s.schoolNewsData,
        resetStatus: s.resetStatus
    }))

    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const onSubmit = (data) => {
        const { title, description } = data
        const newsData = {
            schoolId: SchoolId,
            title: title,
            description: description,
            image: imageUrl
        }
        if (newsId) {
            newsData['id'] = newsId;
            updateNewsAction(newsData)
        }
        else {
            addNewsAction(newsData)
        }
    }

    useEffect(() => {
        if ((updateNewsStatus === STATUS.SUCCESS) || (addNewsStatus === STATUS.SUCCESS)) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addNewsStatus, onClose, reset, resetStatus, updateNewsStatus])

    
    useEffect(() => {
        const findNews = find(schoolNewsData, nData => nData._id === newsId)
        if(newsId){
            const { title, description, image } = findNews || {};
            setValue('title', title)
            setValue('description', description)
            setImageUrl(image)
        }
    }, [newsId, schoolNewsData, setValue, setImageUrl ])

    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size='md'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`${newsId ? 'Update' : 'Add'} News Detail`}</DrawerHeader>

                    <DrawerBody>
                        <Box >
                            <UploadImage title='News Image' extraFormData={[ { key: 'schoolId' , value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl}/>
                            <FormControl isRequired mt={4}>
                                <FormLabel>News Title</FormLabel>
                                <Controller
                                    control={control}
                                    name='title'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='title' size='sm' />
                                    }
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Description</FormLabel>
                                <Controller
                                    control={control}
                                    name='description'
                                    render={({ field }) =>
                                        <Textarea {...field} placeholder='Description' size='sm' />
                                    }
                                />
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' size='sm' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={(addNewsStatus === STATUS.FETCHING) || (updateNewsStatus === STATUS.FETCHING)}
                            bg='#bd0745'
                            _hover={{ bg: '#a3053b' }}
                            color='white'
                            size='sm'
                            type='submit'>
                            {`${newsId ? 'Update' : 'Add'}`}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    )
}