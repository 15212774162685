export const LOCAL_STORAGE_KEYS = {
  showSidebarOpen: 'showSidebarOpen',
  loginToken: 'token'
}

export const STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  INVALID: 'INVALID',
};

export const MARKSTYPE = [
  { title: 'Percentage', value: 'percentage' },
  { title: 'CGPA', value: 'cgpa' }
]