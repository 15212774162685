import { useEffect, useMemo, useState } from "react";
import MainLayout from "../../app/layouts/MainLayout"
import PageHeading from "../../components/PageHeading"
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Box, Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { map, orderBy } from "lodash";
import EmptyBox from "../../components/EmptyBox";
import { LoadingContainer } from "../../components/LoadingContainer";
import { EventDrawer } from "./EventDrawer";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useSchoolInformationStore } from "../../store/personalInformation";
import { useSchoolEventStore } from "../../store/event";
import { STATUS } from "../../constants";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { ConfirmAlert } from "../../components/ConfirmAlert";
import { SchoolId } from "../../config.js";
import dayjs from "dayjs";

const columnHelper = createColumnHelper();
export const Event = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    const [eventId, setEventId] = useState(null)
    const [showDeleteAlert, setShowDeleteAlert] = useState(null)

    const { getEventAction, getEventStatus, schoolEventData, deleteEventAction, deleteEventStatus, resetStatus } = useSchoolEventStore(s => ({
        getEventAction: s.getEventAction,
        getEventStatus: s.getEventStatus,
        schoolEventData: s.schoolEventData,
        deleteEventAction: s.deleteEventAction,
        deleteEventStatus: s.deleteEventStatus,
        resetStatus: s.resetStatus,
    }))

    const columns = useMemo(() => ([
        columnHelper.accessor('name', {
            cell: info => {
                return (
                    <Tooltip label={info.row.original?.name} placement='top'>
                        <Text
                            color='black'
                            display='inline-block'
                            w='220px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'>
                            {info.row.original?.name}
                        </Text>
                    </Tooltip>
                )
            },
            header: () => 'Event Name',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('description', {
            cell: info => {
                return (
                    <Tooltip label={info.row.original?.description} placement='top'>
                        <Text
                            display='inline-block'
                            w='220px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'>
                            {info.row.original?.description}
                        </Text>
                    </Tooltip>
                )
            },
            header: () => 'Event Description',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('date', {
            cell: info => {
                return (
                    <Text>{dayjs(info.row.original?.updatedAt || '').format('DD MMM YYYY')}</Text>
                )
            },
            header: () => 'Event Date',
            footer: info => info.column.id,
        }),
    ]), [])


    const table = useReactTable(useMemo(() => ({
        data: orderBy(schoolEventData, 'ceratedAt', 'desc') || [],
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    }), [columns, schoolEventData]))

    const handleEventDrawer = (id = null) => {
        onOpen()
        setEventId(id)
    }

    useEffect(() => {
        if (!schoolEventData) {
            getEventAction({ schoolId: SchoolId })
        }
    }, [getEventAction, schoolEventData])

    useEffect(() => {
        if (deleteEventStatus === STATUS.SUCCESS) {
            setShowDeleteAlert(null)
            resetStatus()
        }
    }, [deleteEventStatus, resetStatus])

    const handleDeleteEvent = () => {
        deleteEventAction({
            id: showDeleteAlert,
        })
    }

    const toggleDeleteAlert = (id) => {
        setShowDeleteAlert(id)
    }

    return (
        <MainLayout>
            <PageHeading heading='Events' desc='Details of school events' />
            <Box overflow='hidden'>
                <Flex justify='flex-end' color='white' ><Button size='sm' leftIcon={<AddIcon />} bg='#bd0745' _hover={{ bg: '#a3053b' }} onClick={() => handleEventDrawer()}>Add Events</Button></Flex>
                <LoadingContainer loading={getEventStatus === STATUS.FETCHING}>
                    <TableContainer pt={5} style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <Table bg='white' variant={"simple"} >
                            <Thead
                            >
                                {table?.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id} >
                                        {map(headerGroup.headers, header => {
                                            return (
                                                <Th h={10} left={0} key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </Th>
                                            )
                                        })}
                                        <Th>Action</Th>
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows && table.getRowModel().rows?.length ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.original?.id} color='gray.500'>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <Td borderRightColor="gray.100" bg="white" key={cell.id} left={0} maxW={200} overflowWrap="break-word">
                                                            <Box>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </Box>
                                                        </Td>
                                                    )
                                                })}
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={IconButton} icon={<BiDotsVerticalRounded />} variant='flushed' />
                                                        <MenuList>
                                                            <MenuItem icon={<EditIcon />} color='blue.500' onClick={() => handleEventDrawer(row.original._id)}>Edit</MenuItem>
                                                            <MenuItem icon={<DeleteIcon />} color='red.500' onClick={() => toggleDeleteAlert(row.original._id)}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })

                                    :

                                    <Tr >
                                        <Td colSpan={6}>
                                            <EmptyBox title='no events found'
                                                desc="create events"
                                            />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </LoadingContainer>
                {isOpen && <EventDrawer isOpen={isOpen} onClose={onClose} eventId={eventId} />}
                {showDeleteAlert &&
                    <ConfirmAlert
                        isOpen={true}
                        onClose={() => toggleDeleteAlert(null)}
                        message="Are you sure you want to delete event?"
                        heading='delete event'
                        onConfirm={handleDeleteEvent}
                        loading={deleteEventStatus === STATUS.FETCHING} />
                }
            </Box>
        </MainLayout>
    )
}