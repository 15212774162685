import { filter, map } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useSchoolTopperStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addTopperStatus: STATUS.NOT_STARTED,
            updateTopperStatus: STATUS.NOT_STARTED,
            deleteTopperStatus: STATUS.NOT_STARTED,
        })
    },

    getTopperAction: async (payload) => {
        set({ getTopperStatus: STATUS?.FETCHING })
        const response = await apis.getTopperApi(payload)
        if (response.ok) {
            set({ schoolTopperData: response.data, getTopperStatus: STATUS.SUCCESS })
        }
        else {
            set({ getTopperStatus: STATUS.FAILED })
        }
    },

    addTopperAction: async (payload) => {
        set({ addTopperStatus: STATUS.FETCHING })
        const response = await apis.addTopperApi(payload)
        const schoolTopperData = get().schoolTopperData || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ schoolTopperData: ([...schoolTopperData, (response.data)]), addTopperStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addTopperStatus: STATUS.FAILED })
        }
    },

    updateTopperAction: async (payload) => {
        set({ updateTopperStatus: STATUS.FETCHING })
        const response = await apis.updateTopperApi(payload)
        const schoolTopperData = get().schoolTopperData || []
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ schoolTopperData: map(schoolTopperData, d => response.data._id === d._id ? response.data : d), updateTopperStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateTopperStatus: STATUS.FAILED })
        }
    },

    deleteTopperAction: async (payload) => {
        set({ deleteTopperStatus: STATUS.FETCHING });
        const { ok } = await apis.deleteTopperApi(payload)
        if (ok) {
            SuccessAlert('Deleted Successfully')
            let schoolTopperData = get().schoolTopperData;
            schoolTopperData = filter(schoolTopperData, d => d._id !== payload.id);
            set({ schoolTopperData: schoolTopperData, deleteTopperStatus: STATUS.SUCCESS, })
        }
        else {
            ErrorAlert();
            set({ deleteTopperStatus: STATUS.FAILED })
        }
    },

}))
