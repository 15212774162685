import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { UploadImage } from "../../components/UploadImage"
import { Controller, useForm } from "react-hook-form"
import { useSchoolEventStore } from "../../store/event"
import { STATUS } from "../../constants"
import { find } from "lodash"
import dayjs from "dayjs"
import { ConvertDate } from "../../components/ConvertDate"
import { SchoolId } from "../../config.js"

export const EventDrawer = ({ isOpen, onClose, eventId }) => {
    const ref = useRef()
    const [imageUrl, setImageUrl] = useState()

    const { control, handleSubmit, setValue, reset } = useForm()

    const { addEventStatus, addEventAction, updateEventAction, updateEventStatus, resetStatus, schoolEventData } = useSchoolEventStore(s => ({
        addEventAction: s.addEventAction,
        addEventStatus: s.addEventStatus,
        updateEventAction: s.updateEventAction,
        updateEventStatus: s.updateEventStatus,
        schoolEventData: s.schoolEventData,
        resetStatus: s.resetStatus
    }))

    const onSubmit = (data) => {
        const { name, description, date } = data
        const EventData = {
            schoolId: SchoolId,
            name: name,
            description: description,
            image: imageUrl,
            date: ConvertDate(date)
        }
        if (eventId) {
            EventData['id'] = eventId;
            updateEventAction(EventData)
        }
        else {
            addEventAction(EventData)
        }
    }

    useEffect(() => {
        if ((updateEventStatus === STATUS.SUCCESS) || (addEventStatus === STATUS.SUCCESS)) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addEventStatus, onClose, reset, resetStatus, updateEventStatus])


    useEffect(() => {
        const findEvent = find(schoolEventData, nData => nData._id === eventId)
        const { name, description, date, image } = findEvent || {};
        if (eventId) {
            setValue('name', name)
            setValue('description', description)
            setValue('date', date ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null) 
            setImageUrl(image)
        }
    }, [eventId, schoolEventData, setValue, setImageUrl])

    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size='md'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`${eventId ? 'Update' : 'Add'} Event`}</DrawerHeader>

                    <DrawerBody>
                        <Box >
                            <UploadImage title='Event Image' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                            <FormControl isRequired mt={4}>
                                <FormLabel>Event Title</FormLabel>
                                <Controller
                                    control={control}
                                    name='name'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='Name' size='sm' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Date</FormLabel>
                                <Controller
                                    control={control}
                                    name='date'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='date' type='date' size='sm' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Description</FormLabel>
                                <Controller
                                    control={control}
                                    name='description'
                                    render={({ field }) =>
                                        <Textarea {...field} placeholder='Description' size='sm' />}
                                />
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' size='sm' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            isLoading={(addEventStatus === STATUS.FETCHING) || (updateEventStatus === STATUS.FETCHING)}
                            bg='#bd0745'
                            _hover={{ bg: '#a3053b' }}
                            color='white'
                            size='sm'
                            type='submit'
                        >
                            {eventId ? 'Update': 'Add'}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    )
}