import { useEffect, useState } from "react"
import MainLayout from "../app/layouts/MainLayout"
import PageHeading from "../components/PageHeading"
import { Box, Button, Center, Flex, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { useSchoolInformationStore } from "../store/personalInformation"
import { STATUS } from "../constants"
import { map } from "lodash"
import { UploadImage } from "../components/UploadImage"
import { SchoolId } from "../config.js"

export const Achivement = () => {
    const { control, setValue, handleSubmit } = useForm()
   
    const [imageUrl, setImageUrl] = useState()

    const { addSchoolInformationStatus, addSchoolInformationAction, updateSchoolInformationAction, updateSchoolInformationStatus, schoolData } = useSchoolInformationStore(s => ({
        addSchoolInformationAction: s.addSchoolInformationAction,
        addSchoolInformationStatus: s.addSchoolInformationStatus,
        updateSchoolInformationAction: s.updateSchoolInformationAction,
        updateSchoolInformationStatus: s.updateSchoolInformationStatus,
        schoolData: s.schoolData,
    }))

    const onSubmit = (data) => {
        const { alumini, students, studentCampuses, teachers, description } = data
        const achivementData = {
            achievementDetails: {
                image: imageUrl,
                des: description,
                achivements: [
                    { title: 'alumini', value: alumini },
                    { title: 'students', value: students },
                    { title: 'studentCampuses', value: studentCampuses },
                    { title: 'teachers', value: teachers },
                ]
            }
        }
        if (schoolData && SchoolId) {
            achivementData['id'] = SchoolId;
            updateSchoolInformationAction(achivementData)
        }
        else {
            addSchoolInformationAction(achivementData)
        }
    }

    useEffect(() => {
        if (schoolData && schoolData.achievementDetails) {
            const { achivements, des, image } = schoolData?.achievementDetails
            achivements && achivements.length && map(achivements, ({ title, value }) => {
                if(value !== "undefined"){
                    setValue(title, value)
                }
            }
            )
            setValue('description', des)
            setImageUrl(image)
        }
    }, [schoolData, setValue, setImageUrl])

    const getImageUrl = (url) => {
        setImageUrl(url)
    }
    return (
        <MainLayout>
            <PageHeading heading='Achivement' desc='Fill school avhievements details'/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Center>
                    <Box borderRadius='3px' border='1px solid' borderColor='#ccc' p={3} w='600px'>
                        <UploadImage title='Achievement Image' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                        <FormControl isRequired mt={4}>
                            <FormLabel>No. of Alumini</FormLabel>
                            <Controller
                                control={control}
                                name='alumini'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Alumini' type='number' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>No. of Students</FormLabel>
                            <Controller
                                control={control}
                                name='students'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Students' type='number' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>No. of Teachers</FormLabel>
                            <Controller
                                control={control}
                                name='teachers'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Teachers' type='number' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>No. of Student Campuses</FormLabel>
                            <Controller
                                control={control}
                                name='studentCampuses'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Student Campuses' type='number' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Description</FormLabel>
                            <Controller
                                control={control}
                                name='description'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='Description' size='sm' />
                                }
                            />
                        </FormControl>
                        <Flex justify='flex-end' my={4}>
                            <Button
                                isLoading={(updateSchoolInformationStatus === STATUS.FETCHING) || (addSchoolInformationStatus === STATUS.FETCHING)}
                                type='submit'
                                color='white'
                                bg='#bd0745'
                                _hover={{ bg: '#a3053b' }}
                                size='sm'>
                                Add
                            </Button>
                        </Flex>
                    </Box>
                </Center>
            </form>
        </MainLayout>
    )
}