import { Box, Center, Flex, HStack, Text } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { LoadingContainer } from "../../components/LoadingContainer"
import PageHeading from "../../components/PageHeading"
import Sidebar from "../../app/layouts/SideBar"
import { map } from "lodash"
import { BsPersonExclamation, BsQuestionCircle } from "react-icons/bs"
import { FaChalkboardTeacher } from "react-icons/fa"
import { MdOutlineFeedback } from "react-icons/md"
import { useDashBoardStore } from "../../store/dashboard"
import { useEffect } from "react"
import { SchoolId } from "../../config"
import { STATUS } from "../../constants"

export const Dashboard = () => {

    const {getDashBoardAction, getDashBoardStatus, dashBoard } = useDashBoardStore(s => ({
        getDashBoardAction: s.getDashBoardAction,
        getDashBoardStatus: s.getDashBoardStatus,
        dashBoard: s.dashBoard,
    }))

    useEffect(() => {
        if(!dashBoard && !dashBoard?.length){
            getDashBoardAction({ schoolId: SchoolId })
        }
    }, [dashBoard, getDashBoardAction])

    const { admissionEnquiry, staffCount, feedbackCount, complaintCount } = dashBoard || {};

    const count = [
        {title: 'Teachers', total:staffCount, icon:<FaChalkboardTeacher fontSize='34px' color='white'/>, bg:'#4f00c4'}, 
        {title: 'Feedbacks', total: feedbackCount, icon:<MdOutlineFeedback fontSize='34px' color='white'/>, bg:'#bd0745'}, 
        {title: 'Complaints', total:complaintCount, icon:<BsPersonExclamation fontSize='34px' color='white' />, bg:'#cc2607'}, 
        {title: 'Enquiries', total:admissionEnquiry, icon:<BsQuestionCircle fontSize='34px' color='white'/>, bg:'#cc7700'}, 
    ]
     return (
        <MainLayout Sidebar={<Sidebar />}>
            <PageHeading heading='DashBoard' />
            <LoadingContainer loading={ getDashBoardStatus === STATUS.FETCHING }>
                <Flex wrap='wrap' w='100%' justify='flex-start' flexDir={{base:'column', md:'row', lg:'row'}}>
                    {map(count, (c, index) =>
                        <Flex w='25%' minW='240px' mr={10} my={4} border='1px solid' borderColor='white' bg={c.bg} borderRadius='6px'>
                            <Box key={index} flex={3 / 4} borderLeftRadius='6px' px={4} py={6} >
                                <HStack align='start'>
                                    <Center w='fit-content' minW='30px' h='30px' p={1} borderRadius={6} bg='white'>
                                        <Text fontSize={20} fontWeight={500} color={c.bg}>{c.total}</Text>
                                    </Center>
                                    <Text fontSize={20} fontWeight={500} color='white'>{c.title}</Text>
                                </HStack>
                            </Box>
                            <Center flex={1 / 4} px='10px' borderRightRadius='6px' borderLeft='1px solid' borderColor='white'>
                                {c.icon}
                            </Center>
                        </Flex>
                    )}
                </Flex>

            </LoadingContainer>
        </MainLayout>
    )
}