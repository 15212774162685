import { apiClient, URIS } from '.';

export const apis = {
      /* School Information  */
      addSchoolInformationApi: (payload) => apiClient.post(URIS.ADD_SCHOOL_INFORMATION, payload),
      deleteSchoolInformationApi: (payload) => apiClient.delete(URIS.ADD_SCHOOL_INFORMATION, payload),
      updateSchoolInformationApi: (payload) => apiClient.patch(URIS.ADD_SCHOOL_INFORMATION, payload),
      getSchoolInformationApi: (payload) => apiClient.get(URIS.GET_SCHOOL_INFORMATION, payload),

      /* News */
      addNewsApi: (payload) => apiClient.post(URIS.ADD_NEWS, payload),
      deleteNewsApi: (payload) => apiClient.delete(URIS.ADD_NEWS, payload),
      updateNewsApi: (payload) => apiClient.patch(URIS.ADD_NEWS, payload),
      getNewsApi: (payload) => apiClient.get(URIS.GET_NEWS, payload),

      /* Event */
      addEventApi: (payload) => apiClient.post(URIS.ADD_EVENT, payload),
      deleteEventApi: (payload) => apiClient.delete(URIS.ADD_EVENT, payload),
      updateEventApi: (payload) => apiClient.patch(URIS.ADD_EVENT, payload),
      getEventApi: (payload) => apiClient.get(URIS.GET_EVENT, payload),

      /* Topper */
      addTopperApi: (payload) => apiClient.post(URIS.ADD_TOPPER, payload),
      deleteTopperApi: (payload) => apiClient.delete(URIS.ADD_TOPPER, payload),
      updateTopperApi: (payload) => apiClient.patch(URIS.ADD_TOPPER, payload),
      getTopperApi: (payload) => apiClient.get(URIS.GET_TOPPER, payload),

      /* Feedback */
      getFeedbackApi: (payload) => apiClient.get(URIS.GET_FEEDBACK, payload),

      /* Admission Enquiry */
      getAdmissionEnquiryApi: (payload) => apiClient.get(URIS.GET_ADMISSION_ENQUIRY, payload),

      /* Facility */
      addFacilityApi : (payload) => apiClient.post(URIS.ADD_FACILITY, payload),
      updateFacilityApi : (payload) => apiClient.patch(URIS.ADD_FACILITY, payload),
      getFacilityApi : (payload) => apiClient.get(URIS.GET_FACILITY, payload),

      /* Upload Image */
      uploadImageApi : (payload) => apiClient.post(URIS.UPLOAD_IMAGE, payload),
      deleteImageApi : (payload) => apiClient.delete(URIS.UPLOAD_IMAGE, payload),

      /* Upload Multiple Image */
      uploadMultipleImageApi : (payload) => apiClient.post(URIS.UPLOAD_MULTIPLE_IMAGE, payload),

      /* States */
      getStateApi : (payload) => apiClient.get(URIS.GET_STATES, payload),

      /* Staff */
      addStaffApi: (payload) => apiClient.post(URIS.ADD_STAFF, payload),
      updateStaffApi: (payload) => apiClient.patch(URIS.ADD_STAFF, payload),
      deleteStaffApi: (payload) => apiClient.delete(URIS.ADD_STAFF, payload),
      getStaffApi: (payload) => apiClient.get(URIS.GET_STAFF, payload),

      /* Crousal */
      updateCrousalApi: (payload) => apiClient.patch(URIS.ADD_CROUSAL, payload),
      addCrousalApi: (payload) => apiClient.post(URIS.ADD_CROUSAL, payload),
      getCrousalApi: (payload) => apiClient.get(URIS.GET_CROUSAL, payload),

      /* Login */
      loginUser: (payload) => apiClient.post(URIS.LOGIN, payload),

      /* Dashboard */
      getDashboardApi: (payload) => apiClient.get(URIS.DASHBOARD_COUNT, payload),

      /* Gallery*/
      createGalleryApi: (payload) => apiClient.post(URIS.GALLERY, payload),
      updateGalleryApi: (payload) => apiClient.patch(URIS.GALLERY, payload),
      deleteGalleryApi: (payload) => apiClient.delete(URIS.GALLERY, payload),
      getGalleryApi: (payload) => apiClient.get(URIS.GET_GALLERY, payload),
};
