import { useEffect, useMemo, useState } from "react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Avatar, Box, Button, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { BiDotsVerticalRounded } from "react-icons/bi";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

import MainLayout from "../../app/layouts/MainLayout"
import { filter, map, orderBy } from "lodash";
import { StaffDrawer } from "./StaffDrawer";
import { useStaffStore } from "../../store/staff";
import { STATUS } from "../../constants";

import EmptyBox from "../../components/EmptyBox";
import { LoadingContainer } from "../../components/LoadingContainer";
import PageHeading from "../../components/PageHeading"
import { ConfirmAlert } from "../../components/ConfirmAlert";
import { useSchoolInformationStore } from "../../store/personalInformation";
import { SchoolId } from "../../config.js";


const columnHelper = createColumnHelper();
export const Staff = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    const [showDeleteAlert, setShowDeleteAlert] = useState(null)
    const [staffId, setStaffId] = useState(null)

    const { deleteStaffAction, deleteStaffStatus, getStaffAction, getStaffStatus, resetStatus, staff } = useStaffStore(s => ({
        deleteStaffAction: s.deleteStaffAction,
        deleteStaffStatus: s.deleteStaffStatus,
        getStaffStatus: s.getStaffStatus,
        getStaffAction: s.getStaffAction,
        resetStatus: s.resetStatus,
        staff: s.staff,
    }))
    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const columns = useMemo(() => ([
        columnHelper.accessor('name', {
            cell: info => {
                return (
                    <HStack spacing={3}>
                        <Image w='40px' h='40px' borderRadius={50} size='sm' src={info.row.original.avatar || '/assets/profile.webp'} crossOrigin='anonymous' />
                        <Box cursor='pointer' >
                            <Text color='defaultColor.400' fontWeight='semibold'>{info.row.original.name || ''}</Text>
                        </Box>
                    </HStack>
                )
            },
            header: () => 'Staff Details',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('designation', {
            header: () => 'Staff Designation',
            footer: info => info.column.id,
        }),
    ]), [])
    
    const staffData = useMemo(() => filter(staff, s => s.staffType !== 'DESK'), [staff])
    const table = useReactTable(useMemo(() => ({
        data: orderBy(staffData, 'createdAt', 'desc') || [],
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    }), [columns, staffData]))

    useEffect(() => {
        if (deleteStaffStatus === STATUS.SUCCESS) {
            setShowDeleteAlert(null)
            resetStatus()
        }
    }, [deleteStaffStatus, resetStatus])

    useEffect(() => {
        if (!staffData?.length && schoolData) {
            getStaffAction({ schoolId: SchoolId })
        }
    }, [getStaffAction, schoolData, staffData?.length])

    const handleDeleteStaff = () => {
        deleteStaffAction({
            id: showDeleteAlert,
        })
    }

    const toggleDeleteAlert = (id) => {
        setShowDeleteAlert(id)
    }

    const handleStaffDrawer = (id = null) => {
        onOpen()
        setStaffId(id)
    }

    return (
        <MainLayout>
            <PageHeading heading='Staff' desc='Details of staff and their designations' />
            <Box>
                <Flex justify='flex-end' color='white'><Button size='sm' bg='#bd0745' _hover={{ bg: '#a3053b' }} leftIcon={<AddIcon />} onClick={() => handleStaffDrawer()}>Add Staff</Button></Flex>
                <LoadingContainer loading={getStaffStatus === STATUS.FETCHING}>
                    <TableContainer pt={5} style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <Table bg='white' variant={"simple"} >
                            <Thead
                            >
                                {table?.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id} >
                                        {map(headerGroup.headers, header => {
                                            return (
                                                <Th h={10} left={0} key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </Th>
                                            )
                                        })}
                                        <Th>Action</Th>
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows && table.getRowModel().rows?.length ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.original?.id} color='gray.500'>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <Td borderRightColor="gray.100" bg={"white"} key={cell.id} left={0} maxW={200} overflowWrap="break-word">
                                                            <Box>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </Box>
                                                        </Td>
                                                    )
                                                })}
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={IconButton} icon={<BiDotsVerticalRounded />} variant='flushed' />
                                                        <MenuList>
                                                            <MenuItem icon={<EditIcon />} color='blue.500' onClick={() => handleStaffDrawer(row.original._id)}>Edit</MenuItem>
                                                            <MenuItem icon={<DeleteIcon />} color='red.500' onClick={() => toggleDeleteAlert(row.original._id)}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })

                                    :

                                    <Tr >
                                        <Td colSpan={6}>
                                            <EmptyBox title='no staff found'
                                                desc="create staff"
                                            />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </LoadingContainer>
                {isOpen && <StaffDrawer isOpen={isOpen} onClose={onClose} staffId={staffId} />}
                {showDeleteAlert &&
                    <ConfirmAlert
                        isOpen={true}
                        onClose={() => toggleDeleteAlert(null)}
                        message="Are you sure you want to delete staff?"
                        heading='delete staff'
                        onConfirm={handleDeleteStaff}
                        loading={deleteStaffStatus === STATUS.FETCHING} />
                }
            </Box>
        </MainLayout>
    )
}