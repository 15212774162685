import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Input, Textarea } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { UploadImage } from "../../components/UploadImage"
import { STATUS } from "../../constants"
import { useStaffStore } from "../../store/staff"
import { Controller, useForm } from "react-hook-form"
import { find } from "lodash"
import { SchoolId } from "../../config.js"

export const DeskDrawer = ({ isOpen, onClose, staffId }) => {
    const [imageUrl, setImageUrl] = useState()

    const {control, reset, setValue, handleSubmit} = useForm()

    const { addStaffAction, addStaffStatus, updateStaffAction, updateStaffStatus, resetStatus, staff } = useStaffStore(s => ({
        addStaffAction: s.addStaffAction,
        addStaffStatus: s.addStaffStatus,
        updateStaffAction: s.updateStaffAction,
        updateStaffStatus: s.updateStaffStatus,
        resetStatus: s.resetStatus,
        staff: s.staff,
    }))

    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    const onSubmit = (data) => {
        const { name, designation, description } = data || {};
        const staffData = {
            schoolId: SchoolId,
            name: name,
            designation: designation,
            avatar: imageUrl,
            desc: description,
            staffType:'DESK'

        }
        if (staffId) {
            staffData['id'] = staffId;
            updateStaffAction(staffData);
        }
        else {
            addStaffAction(staffData);
        }
    }

    useEffect(() => {
        if ((addStaffStatus === STATUS.SUCCESS) || (updateStaffStatus === STATUS.SUCCESS)) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addStaffStatus, onClose, reset, resetStatus, updateStaffStatus])

    const particularStaff = find(staff, s => s._id === staffId)
    useEffect(() => {
        const { name, designation, desc, avatar } = particularStaff || {};
        if (particularStaff) {
            setValue('name', name)
            setValue('designation', designation)
            setValue('description', desc)
            setImageUrl(avatar)
        }
    }, [setValue, particularStaff, setImageUrl])

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size='md'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`${staffId ? 'Update' : 'Add'} Desk`}</DrawerHeader>

                    <DrawerBody>
                        <Box >
                            <UploadImage title='Image' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                            <FormControl isRequired mt={4}>
                                <FormLabel>Name</FormLabel>
                                <Controller
                                    control={control}
                                    name='name'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='name' size='sm' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Designation</FormLabel>
                                <Controller
                                    control={control}
                                    name='designation'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='Designation' size='sm' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Description</FormLabel>
                                <Controller
                                    control={control}
                                    name='description'
                                    render={({ field }) =>
                                        <Textarea {...field} placeholder='Description' size='sm' />}
                                />
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' size='sm' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            isLoading={(addStaffStatus === STATUS.FETCHING) || (updateStaffStatus === STATUS.FETCHING)}
                            bg='#bd0745'
                            _hover={{ bg: '#a3053b' }}
                            color='white'
                            size='sm'
                            type='submit'
                        >
                            {staffId ? 'Update' : 'Add'}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    )
}