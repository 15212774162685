import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Box, Button, Center, Flex, FormControl, FormLabel, Image, Input, Select, Textarea } from "@chakra-ui/react"

import MainLayout from "../app/layouts/MainLayout"
import PageHeading from "../components/PageHeading"
import { useSchoolInformationStore } from "../store/personalInformation"
import { STATUS } from "../constants"
import { concat, find, map } from "lodash"
import { UploadImage } from "../components/UploadImage"
import { SchoolId } from "../config.js"

export const PersonalInformation = () => {
    const [imageUrl, setImageUrl] = useState()

    const { control, setValue, handleSubmit, watch } = useForm({ defaultValues: {} })
    const stateId = watch('state')

    const { addSchoolInformationStatus, addSchoolInformationAction, updateSchoolInformationAction, updateSchoolInformationStatus, schoolData, getStateAction, states } = useSchoolInformationStore(s => ({
        addSchoolInformationAction: s.addSchoolInformationAction,
        addSchoolInformationStatus: s.addSchoolInformationStatus,
        updateSchoolInformationAction: s.updateSchoolInformationAction,
        updateSchoolInformationStatus: s.updateSchoolInformationStatus,
        schoolData: s.schoolData,
        getStateAction: s.getStateAction,
        states: s.states,
    }))

    const onSubmit = (data) => {
        const { schoolName, databaseName, address, state, city, email, contact, schoolStartTime, schoolEndTime, whatsApp, facebook, twitter, google, campusTour, instagram, youtube, linkedIn, pinterest } = data
        const schoolInformation = {
            name: schoolName,
            databaseName: databaseName,
            logo: imageUrl,
            email: email,
            address: {
                streetAddress: address,
                state: state,
                city: city
            },
            contact: contact,
            schoolStartTime: schoolStartTime,
            schoolEndTime: schoolEndTime,
        }

        const links = concat(
            whatsApp ? [{ title: 'whatsApp', value: whatsApp }] : [],
            facebook ? [{ title: 'facebook', value: facebook }] : [],
            twitter ? [{ title: 'twitter', value: twitter }] : [],
            google ? [{ title: 'google', value: google }] : [],
            instagram ? [{ title: 'instagram', value: instagram }] : [],
            youtube ? [{ title: 'youtube', value: youtube }] : [],
            linkedIn ? [{ title: 'linkedIn', value: linkedIn }] : [],
            pinterest ? [{ title: 'pinterest', value: pinterest }] : [],
            campusTour ? [{ title: 'campusTour', value: campusTour }] : [],
        );
        schoolInformation['links'] = links;

        if (schoolData && SchoolId) {
            schoolInformation['id'] = SchoolId;
            updateSchoolInformationAction(schoolInformation)
        }
        else {
            addSchoolInformationAction(schoolInformation)
        }
    }

    useEffect(() => {
        if (schoolData) {
            const { name, databaseName, email, address, contact, schoolStartTime, schoolEndTime, logo } = schoolData || {};
            setValue('schoolName', name)
            setValue('databaseName', databaseName)
            setValue('address', address?.streetAddress)
            setValue('state', address?.state)
            setValue('city', address?.city)
            setValue('contact', contact)
            setValue('email', email)
            setValue('schoolStartTime', schoolStartTime)
            setValue('schoolEndTime', schoolEndTime)
            setImageUrl(logo)
        }
    }, [schoolData, setValue, setImageUrl])

    useEffect(() => {
        if (!states && !states?.length) {
            getStateAction()
        }
    }, [getStateAction, states])

    useEffect(() => {
        if (schoolData && schoolData?.links && schoolData?.links?.length) {
            map(schoolData?.links, ({ title, value }) => {
                if (value !== 'undefined') {
                    setValue(title, value);
                }
            })
        }
    }, [schoolData, setValue])


    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    const particularState = find(states, s => s.name === stateId)


    return (
        <MainLayout>
            <PageHeading heading='Personal Infomation' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Center>
                    <Box borderRadius='3px' border='1px solid' borderColor='#ccc' p={3} w='600px'>
                        <UploadImage title='School Logo' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                        <FormControl mt={4}>
                            <FormLabel>Database Name</FormLabel>
                            <Controller
                                control={control}
                                name='databaseName'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Database Name' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>School Name</FormLabel>
                            <Controller
                                control={control}
                                name='schoolName'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Name' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Contact</FormLabel>
                            <Controller
                                control={control}
                                name='contact'
                                render={({ field }) =>
                                    <Input {...field} placeholder='Contact' type='tel' maxLength={10} pattern="[6-9]{1}[0-9]{9}" size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Email</FormLabel>
                            <Controller
                                control={control}
                                name='email'
                                render={({ field }) =>
                                    <Input {...field} placeholder='abc@gmail.com' type="email" size='sm' />
                                }
                            />
                        </FormControl>
                        <Flex align='center'>
                            <FormControl mt={4} mr={2} isRequired>
                                <FormLabel>School Timing Start</FormLabel>
                                <Controller
                                    control={control}
                                    name='schoolStartTime'
                                    render={({ field }) =>
                                        <Input {...field} type="time" size='sm'/>
                                    }
                                />
                            </FormControl>
                            <FormControl mt={4} isRequired>
                                <FormLabel>School Timing End</FormLabel>
                                <Controller
                                    control={control}
                                    name='schoolEndTime'
                                    render={({ field }) =>
                                        <Input {...field} type="time" size='sm' />
                                    }
                                />
                            </FormControl>
                        </Flex>
                        <FormControl mt={4}>
                            <FormLabel>WhatsApp Link</FormLabel>
                            <Controller
                                control={control}
                                name='whatsApp'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://whatsapp.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Facebook Link</FormLabel>
                            <Controller
                                control={control}
                                name='facebook'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://facebook.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Twitter Link</FormLabel>
                            <Controller
                                control={control}
                                name='twitter'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://twitter.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Google Link</FormLabel>
                            <Controller
                                control={control}
                                name='google'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://google.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>YouTube Link</FormLabel>
                            <Controller
                                control={control}
                                name='youtube'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://youtube.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>YouTube Link(Campus Tour)</FormLabel>
                            <Controller
                                control={control}
                                name='campusTour'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://www.youtube.com/embed/VIDEO_ID' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>LinkedIn Link</FormLabel>
                            <Controller
                                control={control}
                                name='linkedIn'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://linkedin.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Instagram Link</FormLabel>
                            <Controller
                                control={control}
                                name='instagram'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://instagram.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Pinterest Link</FormLabel>
                            <Controller
                                control={control}
                                name='pinterest'
                                render={({ field }) =>
                                    <Input {...field} placeholder='https://pinterest.com' size='sm' />
                                }
                            />
                        </FormControl>
                        <Flex justify='space-between' flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                            <FormControl isRequired mt={4} pr={{ lg: 2, md: 2 }}>
                                <FormLabel>State</FormLabel>
                                <Controller
                                    control={control}
                                    name='state'
                                    render={({ field }) =>
                                        <Select {...field} placeholder='Select' size='sm' >
                                            {map(states, state =>
                                                <option key={state.name} value={state.name}>{state.name}</option>
                                            )}
                                        </Select>
                                    }
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>City</FormLabel>
                                <Controller
                                    control={control}
                                    name='city'
                                    render={({ field }) =>
                                        <Select {...field} placeholder='Select' size='sm' >
                                            {map(particularState?.cities, city =>
                                                <option value={city.name} key={city.name}>{city.name}</option>
                                            )}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Flex>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Address</FormLabel>
                            <Controller
                                control={control}
                                name='address'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='Address' size='sm' />
                                }
                            />
                        </FormControl>
                        <Flex justify='flex-end' my={4}>
                            <Button
                                isLoading={(addSchoolInformationStatus === STATUS.FETCHING) || (updateSchoolInformationStatus === STATUS.FETCHING)}
                                type='submit'
                                color='white'
                                bg='#bd0745'
                                _hover={{ bg: '#a3053b' }}
                                size='sm'>
                                {`${SchoolId ? 'Update' : 'Add'}`}
                            </Button>
                        </Flex>
                    </Box>
                </Center>
            </form>
        </MainLayout>
    )
}