import create from 'zustand';
import { LOCAL_STORAGE_KEYS, STATUS } from '../../constants';
import { setAuthorizationHeader } from '../../services/apis';
import { apis } from '../../services/apis/apis'
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useLoginStore = create((set, get) => ({
  schoolDetails: null,
  loginUser: async (payload, resendOtp) => {
     set({ loginStatus: STATUS.FETCHING });
    const { data, ok, originalError } = await apis.loginUser(payload);

    if (ok) {
         set({ loginStatus: STATUS.SUCCESS });
         SuccessAlert('Login Successfully')
        setAuthorizationHeader(data.token);
        localStorage.setItem(LOCAL_STORAGE_KEYS.loginToken, data.token);
        set({ schoolDetails: data.school, school: data.school, otpToken: null,})
      
    } else {
      const isSchoolNotFound = data?.statusCode === 406; 
       if(!isSchoolNotFound && data?.statusCode !== 400 ){
        ErrorAlert(data?.message || originalError.message)
      }
      ErrorAlert(data?.message || originalError.message)
      if(data?.status === 502 ){
        set({ loginStatus: STATUS.NOT_STARTED });
        return;
      }else if(isSchoolNotFound){
        set({ loginStatus: STATUS.NOT_STARTED, otpToken: null });
        return;
      }
      setAuthorizationHeader(null);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.loginToken);
       set({ schoolDetails: null, loginStatus: STATUS.FAILED });
    }
  },

  resetOtpToken: () => {
    set({ otpToken: null, loginStatus: STATUS.NOT_STARTED })
  },

logoutUser: async (d) => {
  set({ logoutStatus : STATUS.FETCHING });
  const res = await apis.logoutUser(d);
  // if (ok) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.loginToken);
      set({ logoutStatus: STATUS.SUCCESS, schoolDetails: null })
  // }
  // else {
  //     ErrorAlert()
  //     set({ logoutStatus: STATUS.FAILED })
  // }
},

}));
