import { useEffect, useMemo } from "react";
import MainLayout from "../../app/layouts/MainLayout"
import PageHeading from "../../components/PageHeading"
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { filter, map, orderBy } from "lodash";
import EmptyBox from "../../components/EmptyBox";
import { LoadingContainer } from "../../components/LoadingContainer";
import { useFeedbackStore } from "../../store/feedback";
import { STATUS } from "../../constants";
import { SchoolId } from "../../config.js";

const columnHelper = createColumnHelper();
export const ComplainList = () => {

    const { getFeedbackAction, getFeedbackStatus, feedback } = useFeedbackStore(s => ({
        getFeedbackAction: s.getFeedbackAction,
        getFeedbackStatus: s.getFeedbackStatus,
        feedback: s.feedback,
    }))

    const columns = useMemo(() => ([
        columnHelper.accessor('name', {
            cell: info => {
                return(
                    <Text color='black'>{info.row?.original?.name}</Text>
                )
            },
            header: () => 'Name',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('email', {
            header: () => 'Email',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('contact', {
            header: () => 'Contact',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('description', {
            cell: info => {
                return (
                    <Tooltip label={info.row?.original?.description}>
                        <Text display='block' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>{info.row?.original?.description}</Text>
                    </Tooltip>
                )
            },
            header: () => 'Description',
            footer: info => info.column.id,
        }),
    ]), [])

    const complaintData = useMemo(() => filter(feedback, f => (f.feedbackType === 'COMPLAINT')), [feedback])

    const table = useReactTable(useMemo(() => ({
        data: orderBy(complaintData, 'createdAt', 'desc') || [],
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    }), [columns, complaintData]))

    useEffect(() => {
        getFeedbackAction({ schoolId: SchoolId })
    }, [getFeedbackAction])

    return (
        <MainLayout>
            <PageHeading heading='Complain List' desc='List of complian form'/>
                <LoadingContainer loading={ getFeedbackStatus === STATUS.FETCHING }>
                    <TableContainer pt={5} style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <Table bg='white'>
                            <Thead
                            >
                                {table?.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id} >
                                        {map(headerGroup.headers, header => {
                                            return (
                                                <Th h={10} left={0} key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </Th>
                                            )
                                        })}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows && table.getRowModel().rows?.length ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.original?.id} color='gray.500'>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <Td borderRightColor="gray.100" bg="white" key={cell.id} left={0} maxW={200} overflowWrap="break-word">
                                                            <Box>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </Box>
                                                        </Td>
                                                    )
                                                })}
                                            </Tr>
                                        )
                                    })

                                    :

                                    <Tr >
                                        <Td colSpan={6}>
                                            <EmptyBox title='no complain found'
                                            />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </LoadingContainer>
        </MainLayout>
    )
}