import { filter, map } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useFeedbackStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addFeedbackStatus: STATUS.NOT_STARTED,
            updateFeedbackStatus: STATUS.NOT_STARTED,
            deleteFeedbackStatus: STATUS.NOT_STARTED,
        })
    },

    getFeedbackAction: async (payload) => {
        set({ getFeedbackStatus: STATUS.FETCHING })
        const response = await apis.getFeedbackApi(payload)
        if (response.ok) {
            set({ feedback: response.data, getFeedbackStatus: STATUS.SUCCESS })
        }
        else {
            set({ getFeedbackStatus: STATUS.FAILED })
        }
    },

}))
