import { useEffect, useMemo, useState } from "react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Avatar, Box, Button, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { BiDotsVerticalRounded } from "react-icons/bi";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

import MainLayout from "../../app/layouts/MainLayout"
import { map, orderBy } from "lodash";
import { STATUS } from "../../constants";

import EmptyBox from "../../components/EmptyBox";
import { LoadingContainer } from "../../components/LoadingContainer";
import PageHeading from "../../components/PageHeading"
import { ConfirmAlert } from "../../components/ConfirmAlert";
import { SchoolId } from "../../config.js";
import { useGalleryStore } from "../../store/gallery";
import { GalleryDrawer } from "./GalleryDrawer";
import { ImagesModal } from "./ImagesModal";
import { RiFolderUploadLine } from "react-icons/ri";


const columnHelper = createColumnHelper();
export const Gallery = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { isOpen: isOpenModal, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure()

    const { addGalleryStatus, getGalleryAction, getGalleryStatus, gallery, deleteGalleryAction, deleteGalleryStatus, resetStatus, album } = useGalleryStore(s => ({
        deleteGalleryAction: s.deleteGalleryAction,
        deleteGalleryStatus: s.deleteGalleryStatus,
        addGalleryStatus: s.addGalleryStatus,
        getGalleryAction: s.getGalleryAction,
        getGalleryStatus: s.getGalleryStatus,
        resetStatus: s.resetStatus,
        gallery: s.gallery,
        album: s.album,
    }))

    const [showDeleteAlert, setShowDeleteAlert] = useState(null)
    const [albumId, setAlbumId] = useState(null)

    const columns = useMemo(() => ([
        columnHelper.accessor('title', {
            cell: info => {
                return (
                    <HStack spacing={3}>
                        <Image w='40px' h='40px' borderRadius={50} size='sm' src={info.row.original.thumbnail || '/assets/profile.webp'} crossOrigin='anonymous' />
                        <Text color='defaultColor.400' fontWeight='semibold'>{info.row.original.title || ''}</Text>
                    </HStack>
                )
            },
            header: () => 'Album Details',
            footer: info => info.column.id,
        }),
    ]), [])

    const table = useReactTable(useMemo(() => ({
        data: orderBy(gallery, 'createdAt', 'desc') || [],
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    }), [columns, gallery]))

    useEffect(() => {
        if (deleteGalleryStatus === STATUS.SUCCESS) {
            setShowDeleteAlert(null)
            resetStatus()
        }
    }, [deleteGalleryStatus, resetStatus])

    useEffect(() => {
        if (!gallery?.length) {
            getGalleryAction({ schoolId: SchoolId })
        }
    }, [getGalleryAction, gallery?.length])

    const handleDeleteStaff = () => {
        deleteGalleryAction({
            id: showDeleteAlert,
        })
    }

    const toggleDeleteAlert = (id) => {
        setShowDeleteAlert(id)
    }

    const handleGalleryDrawer = (id = null) => {
        onOpen()
        setAlbumId(id)
    }

    useEffect(() => {
        if (addGalleryStatus === STATUS.SUCCESS) {
            onOpenModal()
            setAlbumId(album._id)
        }
    }, [addGalleryStatus, album, onOpenModal])

    const handleImagesModal = (albumId) => {
        setAlbumId(albumId)
        onOpenModal()
    }

    return (
        <MainLayout>
            <PageHeading heading='Gallery' desc='Details of album and upload images' />
            <Box>
                <Flex justify='flex-end' color='white'><Button size='sm' bg='#bd0745' _hover={{ bg: '#a3053b' }} leftIcon={<AddIcon />} onClick={() => handleGalleryDrawer()}>Add Album</Button></Flex>
                <LoadingContainer loading={getGalleryStatus === STATUS.FETCHING}>
                    <TableContainer pt={5} style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <Table bg='white' variant={"simple"} >
                            <Thead
                            >
                                {table?.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id} >
                                        {map(headerGroup.headers, header => {
                                            return (
                                                <Th h={10} left={0} key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </Th>
                                            )
                                        })}
                                        <Th>Action</Th>
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows && table.getRowModel().rows?.length ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.original?.id} color='gray.500'>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <Td borderRightColor="gray.100" bg={"white"} key={cell.id} left={0} maxW={200} overflowWrap="break-word">
                                                            <Box>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </Box>
                                                        </Td>
                                                    )
                                                })}
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={IconButton} icon={<BiDotsVerticalRounded />} variant='flushed' />
                                                        <MenuList>
                                                            <MenuItem icon={<EditIcon />} color='blue.500' onClick={() => handleGalleryDrawer(row.original._id)}>Edit Album</MenuItem>
                                                            <MenuItem icon={<RiFolderUploadLine fontSize={15} />} color='green.600' onClick={() => handleImagesModal(row.original._id)}>Upload Images</MenuItem>
                                                            <MenuItem icon={<DeleteIcon />} color='red.500' onClick={() => toggleDeleteAlert(row.original._id)}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })

                                    :

                                    <Tr >
                                        <Td colSpan={6}>
                                            <EmptyBox title='no album found'
                                                desc="create album"
                                            />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </LoadingContainer>
                {isOpen && <GalleryDrawer isOpen={isOpen} onClose={onClose} albumId={albumId} />}
                {isOpenModal && <ImagesModal isOpenModal={isOpenModal} onCloseModal={onCloseModal} albumId={albumId} />}
                {showDeleteAlert &&
                    <ConfirmAlert
                        isOpen={true}
                        onClose={() => toggleDeleteAlert(null)}
                        message="Are you sure you want to delete this album?"
                        heading='delete album'
                        onConfirm={handleDeleteStaff}
                        loading={deleteGalleryStatus === STATUS.FETCHING} />
                }
            </Box>
        </MainLayout>
    )
}