import { Box, HStack } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React from 'react';

export const LoadingContainer = ({
  height = 200,
  loaderSize = 'lg',
  loading = false,
  children,
}) => {
  return (
    <Box>
      {loading ? (
        <HStack minHeight={height} justify="center" align={'center'}>
          <Spinner color="defaultColor.500" size={loaderSize} />
        </HStack>
      ) : (
        children
      )}
    </Box>
  );
};
