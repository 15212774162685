import { useEffect, useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  InputRightElement,
  Image,
  Text
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../store/login";
import { STATUS } from "../../constants";
import { SchoolId } from "../../config";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const { loginUser, loginStatus } = useLoginStore(s => ({ 
    loginUser: s.loginUser, 
    loginStatus: s.loginStatus, 
  }))

  const handleShowClick = () => setShowPassword(!showPassword);
  const navigate = useNavigate()
  const { control, handleSubmit } = useForm()

  const onSubmit = (data) => {
      const { userName, password } = data || {};

      const loginData = {
        username: userName,
        password:password,
        schoolId:SchoolId
      }
      loginUser(loginData)
  }

  useEffect(() => {
    if(loginStatus === STATUS.SUCCESS){
      navigate("/dashboard")
    }
  }, [loginStatus, navigate])

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
        bg="whiteAlpha.900"
        
      >
        <Image src="assets/BrightMoonAcademyLogo.png" alt='Apna School App' w='150px' h='170px' mb='10px' pt='10px' objectFit='cover'/>
        <Text fontWeight={500} fontSize={24}>Login</Text>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={4}
              p="1rem"
              boxShadow="md"
            >
              <FormControl isRequired>
                <Controller
                  name='userName'
                  control={control}
                  render={({ field }) =>
                    <InputGroup {...field}>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<CFaUserAlt color="gray.300" />}
                      />
                      <Input placeholder="Username" />
                    </InputGroup>}
                />
              </FormControl>
              <FormControl>
                <Controller
                  name='password'
                  control={control}
                  render={({ field }) =>
                    <InputGroup {...field}>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        children={<CFaLock color="gray.300" />}
                      />
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                          {showPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>}
                />
                {/* <FormHelperText textAlign="right">
                  <Link>forgot password?</Link>
                </FormHelperText> */}
              </FormControl>
              <Button
              isLoading={loginStatus === STATUS.FETCHING}
                borderRadius={0}
                type="submit"
                variant="solid"
                bg='#1A79BA'
                color='white'
                _hover={{bg:'#186ea8'}}
                width="full"
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
