import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PersonalInformation } from './features/PersonalInformation';
import { About } from './features/About';
import { Achivement } from './features/Achivements';
import { News } from './features/news';
import { Event } from './features/events';
import { Staff } from './features/staff';
import { FeedbackList } from './features/feedback';
import { ComplainList } from './features/complain';
import { EnquiryList } from './features/enquiry';
import Login from './features/login';
import { Desk } from './features/desk';
import { Toppers } from './features/toppers';
import { CrousalImage } from './features/crousal';
import { Dashboard } from './features/dashboard';
import { Facility } from './features/facility/Facility';
import { Gallery } from './features/gallery';

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider>
        <Container />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;

const Container = () => {
  return (
    <Routes>
      <Route path='/' exact element={<Login />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/about-us' element={<About />} />
      <Route path='/staff' element={<Staff />} />
      <Route path='/achivement' element={<Achivement />} />
      <Route path='/toppers' element={<Toppers />} />
      <Route path='/gallery' element={<Gallery />} />
      <Route path='/club' element={<Facility heading='Club' facilityType='CLUB' name='club'/>} />
      <Route path='/sports' element={<Facility heading='Sports' facilityType='SPORTS' name='sports'/>} />
      <Route path='/lab' element={<Facility heading='Lab' facilityType='LAB' name='lab' />} />
      <Route path='/library' element={<Facility heading='Library' facilityType='LIBRARY' name='library' />} />
      <Route path='/hostel' element={<Facility heading='Hostel' facilityType='HOSTEL' name='hostel'/>} />
      <Route path='/news' element={<News />} />
      <Route path='/events' element={<Event />} />
      <Route path='/desk' element={<Desk />} />
      <Route path='/personal-information' element={<PersonalInformation />} />
      <Route path='/carousel' element={<CrousalImage />} />
      <Route path='/feedback-list' element={<FeedbackList/>} />
      <Route path='/complain-list' element={<ComplainList />} />
      <Route path='/enquiry-list' element={<EnquiryList />} />
    </Routes>
  )
}