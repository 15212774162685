import { useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import MainLayout from "../../app/layouts/MainLayout"

import PageHeading from "../../components/PageHeading"
import EmptyBox from "../../components/EmptyBox";
import { ConfirmAlert } from "../../components/ConfirmAlert";
import { LoadingContainer } from "../../components/LoadingContainer";

import { BiDotsVerticalRounded } from "react-icons/bi";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

import { map, orderBy } from "lodash";
import { NewsDrawer } from "./NewsDrawer";
import { useSchoolNewsStore } from "../../store/news";

import { STATUS } from "../../constants";
import { useSchoolInformationStore } from "../../store/personalInformation";
import { SchoolId } from "../../config.js";
import dayjs from "dayjs";

const columnHelper = createColumnHelper();
export const News = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [newsId, setNewsId] = useState(null)
    const [showDeleteAlert, setShowDeleteAlert] = useState(null)

    const { getNewsAction, getNewsStatus, schoolNewsData, deleteNewsAction, deleteNewsStatus, resetStatus } = useSchoolNewsStore(s => ({
        getNewsAction: s.getNewsAction,
        getNewsStatus: s.getNewsStatus,
        schoolNewsData: s.schoolNewsData,
        deleteNewsAction: s.deleteNewsAction,
        deleteNewsStatus: s.deleteNewsStatus,
        resetStatus: s.resetStatus,
    }))

    useEffect(() => {
        if (!schoolNewsData) {
            getNewsAction({ schoolId: SchoolId })
        }
    }, [getNewsAction, schoolNewsData])

    const columns = useMemo(() => ([
        columnHelper.accessor('title', {
            cell: info => {
                return (
                    <Tooltip label={info.row.original?.title} placement='top'>
                        <Text
                            color='black'
                            display='inline-block'
                            w='220px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'>
                            {info.row.original?.title}
                        </Text>
                    </Tooltip>
                )
            },
            header: () => 'Title',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('description', {
            cell: info => {
                return (
                    <Tooltip label={info.row.original?.description} placement='top'>
                        <Text
                            display='inline-block'
                            w='220px'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            textOverflow='ellipsis'>
                            {info.row.original?.description}
                        </Text>
                    </Tooltip>
                )
            },
            header: () => 'Description',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('updated', {
            cell: info => {
                return (
                        <Text>{dayjs(info.row.original?.updatedAt || '').format('DD MMM YYYY')}</Text>
                )
            },
            header: () => 'Update On',
            footer: info => info.column.id,
        }),
    ]), [])

    const handleNewsDrawer = (id = null) => {
        onOpen()
        setNewsId(id)
    }

    useEffect(() => {
        if (deleteNewsStatus === STATUS.SUCCESS) {
            setShowDeleteAlert(null)
            resetStatus()
        }
    }, [deleteNewsStatus, resetStatus])

    const handleDeleteNews = () => {
        deleteNewsAction({
            id: showDeleteAlert,
        })
    }

    const toggleDeleteAlert = (id) => {
        setShowDeleteAlert(id)
    }


    const table = useReactTable(useMemo(() => ({
        data: orderBy(schoolNewsData, 'createdAt', 'desc') || [],
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    }), [columns, schoolNewsData]))

    return (
        <MainLayout>
            <PageHeading heading='News' desc='Details of school news'/>
            <Box>
                <Flex justify='flex-end' color='white'><Button size='sm' bg='#bd0745' _hover={{ bg: '#a3053b' }} leftIcon={<AddIcon />} onClick={() => handleNewsDrawer()}>Add News</Button></Flex>
                <LoadingContainer loading={getNewsStatus === STATUS.FETCHING}>
                    <TableContainer pt={5} style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <Table bg='white' variant={"simple"} >
                            <Thead
                            >
                                {table?.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id} >
                                        {map(headerGroup.headers, header => {
                                            return (
                                                <Th h={10} left={0} key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </Th>
                                            )
                                        })}
                                        <Th>Action</Th>
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows && table.getRowModel().rows?.length ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.original?.id} color='gray.500'>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <Td borderRightColor="gray.100" bg={"white"} key={cell.id} left={0} maxW={200} overflowWrap="break-word">
                                                            <Box>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </Box>
                                                        </Td>
                                                    )
                                                })}
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={IconButton} icon={<BiDotsVerticalRounded />} variant='flushed' />
                                                        <MenuList>
                                                            <MenuItem icon={<EditIcon />} color='blue.500' onClick={() => handleNewsDrawer(row.original._id)}>Edit</MenuItem>
                                                            <MenuItem icon={<DeleteIcon />} color='red.500' onClick={() => toggleDeleteAlert(row.original._id)}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })

                                    :

                                    <Tr >
                                        <Td colSpan={6}>
                                            <EmptyBox title='no news found'
                                                desc="create news"
                                            />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </LoadingContainer>
                {isOpen && <NewsDrawer isOpen={isOpen} newsId={newsId} onClose={onClose} />}
                {showDeleteAlert &&
                    <ConfirmAlert
                        isOpen={true}
                        onClose={() => toggleDeleteAlert(null)}
                        message="Are you sure you want to delete news?"
                        heading='delete news'
                        onConfirm={handleDeleteNews}
                        loading={deleteNewsStatus === STATUS.FETCHING} />
                }
            </Box>
        </MainLayout>
    )
}