import { filter, map } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useDashBoardStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
        })
    },

    getDashBoardAction: async (payload) => {
        set({ getDashBoardStatus: STATUS.FETCHING })
        const response = await apis.getDashboardApi(payload)
        if (response.ok) {
            set({ dashBoard: response.data, getDashBoardStatus: STATUS.SUCCESS })
        }
        else {
            set({ getDashBoardStatus: STATUS.FAILED })
        }
    },

}))
