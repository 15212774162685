import { Box, Heading } from '@chakra-ui/react'
import React from 'react'

export default function HeadingX(props) {
    
    return (
        <>
            <Heading fontSize={'lg'} color='defaultColor.800' {...props}>{props.children}</Heading>
            {
                props.desc && 
                <Box color='gray.500' fontSize='sm'>
                    { props.desc }
                </Box>
            }
        
        </>
    )
}
