import { useEffect, useState } from "react"
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"

import { UploadImage } from "../../components/UploadImage"

import { STATUS } from "../../constants"
import { find } from "lodash"
import { useGalleryStore } from "../../store/gallery"
import { SchoolId } from "../../config"

export const GalleryDrawer = ({ isOpen, onClose, albumId }) => {
    const { addGalleryAction, addGalleryStatus, updateGalleryAction, updateGalleryStatus, gallery, resetStatus } = useGalleryStore(s => ({
        addGalleryAction: s.addGalleryAction,
        addGalleryStatus: s.addGalleryStatus,
        updateGalleryAction: s.updateGalleryAction,
        updateGalleryStatus: s.updateGalleryStatus,
        resetStatus: s.resetStatus,
        gallery: s.gallery,
    }))

    const { control, handleSubmit, setValue, reset } = useForm()

    const [imageUrl, setImageUrl] = useState()

    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    const onSubmit = (data) => {
        const { title, } = data
        const gallery = {
            title: title,
            thumbnail: imageUrl
        }

        if (albumId) {
            gallery['id'] = albumId;
            updateGalleryAction(gallery)
        }
        else {
            addGalleryAction(gallery)
        }
    }

    useEffect(() => {
        if ((addGalleryStatus === STATUS.SUCCESS) || (updateGalleryStatus === STATUS.SUCCESS)) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addGalleryStatus, onClose, reset, resetStatus, updateGalleryStatus])

    const particularAlbum = find(gallery, g => g._id === albumId)
    
    useEffect(() => {
        const { title, thumbnail } = particularAlbum || {};
        if (particularAlbum) {
            setValue('title', title)
            setImageUrl(thumbnail)
        }
    }, [setValue, particularAlbum])

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size='md'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`${albumId ? 'Update' : 'Add'} Staff`}</DrawerHeader>

                    <DrawerBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box>
                                <UploadImage title='Thumbnail' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />

                                <FormControl mt={4} isRequired>
                                    <FormLabel>Album Title</FormLabel>
                                    <Controller
                                        control={control}
                                        name='title'
                                        render={({ field }) =>
                                            <Input {...field} placeholder='Title' size='sm' />
                                        }
                                    />
                                </FormControl>
                            </Box>
                        </form>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' size='sm' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={(addGalleryStatus === STATUS.FETCHING) || (updateGalleryStatus === STATUS.FETCHING)}
                            bg='#bd0745'
                            _hover={{ bg: '#a3053b' }}
                            color='white'
                            size='sm'
                            type='submit'
                        >
                            {albumId ? 'Update' : 'Add'}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    )
}