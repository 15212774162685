import { Box, Button, Center, Flex, HStack, Image, Text } from '@chakra-ui/react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useEffect, useState } from 'react'
import { useSchoolInformationStore } from '../../store/personalInformation';
import { setSchoolHeader } from '../../services/apis';
import { useSchoolFacilityStore } from '../../store/facility';
import { SchoolId } from '../../config.js';
import Profile from '../../features/profile';

export default function Header({ toggleSidebarMenu }) {
    const [editProfileModal, setEditProfileModal] = useState(false);

    const { getSchoolInformationAction, schoolData } = useSchoolInformationStore(s => ({
        getSchoolInformationAction: s.getSchoolInformationAction,
        schoolData: s.schoolData,
    }))
    const { getSchoolFacilityAction } = useSchoolFacilityStore(s => ({ getSchoolFacilityAction: s.getSchoolFacilityAction }))

    useEffect(() => {
        if (!schoolData) {
            setSchoolHeader(SchoolId)
            getSchoolInformationAction({ id: SchoolId })
            getSchoolFacilityAction({ id: SchoolId })
        }
    }, [getSchoolInformationAction, schoolData, getSchoolFacilityAction])

    return (
        <Box px={{ base: 3, lg: 6 }} py={2} borderBottom='1px solid' borderColor={'gray.200'}>
            <Flex justify='space-between'>
                <Flex align={{ base: 'start', lg: 'center' }}>
                    <Box onClick={toggleSidebarMenu} mr={{ base: 2, lg: 0 }} alignSelf='center' display={{ base: 'block', lg: 'none' }}>
                        <GiHamburgerMenu />
                    </Box>
                    <Flex align='center' cursor='pointer'>
                        <HStack w='fit-content'>
                            <Image src={'assets/BrightMoonAcademyLogo.png'} px={{ base: 1, lg: 2 }} transition='all 1s' w={[{ base: '239px', md: '363px', lg: '363px' }]} h={{ lg: '76px', base: '50px' }} mr={{ base: '5px' }} alt='image' crossOrigin='anonymous' />
                            <Text color='#6b0f11' fontWeight={700} textShadow='2px 1px 1px #e8c480' fontSize={{ lg: '26px' }}>{schoolData?.name}</Text>
                        </HStack>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Button onClick={() => setEditProfileModal(true)} variant={'ghost'} py={6}>
                        <HStack spacing={2}>
                            <Box>
                                <Box maxW={100}>
                                    <Box textAlign='left' fontSize={{ base: 'md', lg: 'lg' }} noOfLines={1} color='defaultColor.800'>{schoolData?.username}</Box>
                                </Box>
                            </Box>
                        </HStack>
                    </Button>
                </Flex>
            </Flex>
            <Profile isOpen={editProfileModal} onClose={() => setEditProfileModal(false)} />
        </Box>
    )
}

