import { useEffect, useMemo, useState } from "react";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Avatar, Box, Button, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import MainLayout from "../../app/layouts/MainLayout"

import PageHeading from "../../components/PageHeading"
import { LoadingContainer } from "../../components/LoadingContainer";
import EmptyBox from "../../components/EmptyBox";

import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";

import { map, orderBy } from "lodash";
import { TopperDrawer } from "./ToppersDrawer";
import { useSchoolTopperStore } from "../../store/topper";
import { STATUS } from "../../constants";
import { ConfirmAlert } from "../../components/ConfirmAlert";
import { SchoolId } from "../../config.js";

const columnHelper = createColumnHelper();
export const Toppers = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [topperId, setTopperId] = useState(null)
    const [showDeleteAlert, setShowDeleteAlert] = useState(null)

    const { getTopperAction, getTopperStatus, deleteTopperAction, deleteTopperStatus, resetStatus, schoolTopperData } = useSchoolTopperStore(s => ({
        getTopperAction: s.getTopperAction,
        getTopperStatus: s.getTopperStatus,
        schoolTopperData: s.schoolTopperData,
        deleteTopperAction: s.deleteTopperAction,
        deleteTopperStatus: s.deleteTopperStatus,
        resetStatus: s.resetStatus,
    }))

    const handleMarksType = (key) => {
        switch (key) {
            case 'percentage':
                return 'Percentage';
            case 'cgpa':
                return 'CGPA';
            default:
                return;
        }
    }

    const columns = useMemo(() => ([
        columnHelper.accessor('studentName', {
            cell: info => {
                return (
                    <HStack spacing={3}>
                        <Image w='40px' h='40px' borderRadius={50} src={info.row.original.avatar || '/assets/profile.webp'} crossOrigin="anonymous" />
                        <Box cursor='pointer' >
                            <Text color='defaultColor.400' fontWeight='semibold'>{info.row.original.studentName || ''}</Text>
                        </Box>
                    </HStack>
                )
            },
            header: () => 'Student Name',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('session', {
            header: () => 'session',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('class', {
            header: () => 'Class',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('marks', {
            header: () => 'Marks/CGPA',
            footer: info => info.column.id,
        }),
        columnHelper.accessor('marksType', {
            cell: info => {
                return (
                    handleMarksType(info.row?.original?.marksType)
                )
            },
            header: () => 'Marks Type',
            footer: info => info.column.id,
        }),
    ]), [])

    const table = useReactTable(useMemo(() => ({
        data: orderBy(schoolTopperData, 'createdAt', 'desc') || [],
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    }), [columns, schoolTopperData]))

    const handleTopperDrawer = (id = null) => {
        onOpen()
        setTopperId(id)
    }

    useEffect(() => {
        if (deleteTopperStatus === STATUS.SUCCESS) {
            setShowDeleteAlert(null)
            resetStatus()
        }
    }, [deleteTopperStatus, resetStatus])

    const handleDeleteTopper = () => {
        deleteTopperAction({
            id: showDeleteAlert,
        })
    }

    const toggleDeleteAlert = (id) => {
        setShowDeleteAlert(id)
    }

    useEffect(() => {
        if (!schoolTopperData) {
            getTopperAction({ schoolId: SchoolId })
        }
    }, [getTopperAction, schoolTopperData])

    return (
        <MainLayout>
            <PageHeading heading='Toppers' desc='Details of topper students and their marks'/>
            <Box overflow='hidden'>
                <Flex justify='flex-end' color='white' ><Button size='sm' leftIcon={<AddIcon />} bg='#bd0745' _hover={{ bg: '#a3053b' }} onClick={() => handleTopperDrawer()}>Add Topper</Button></Flex>
                <LoadingContainer loading={getTopperStatus === STATUS.FETCHING}>
                    <TableContainer pt={5} style={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <Table bg='white' variant={"simple"} >
                            <Thead
                            >
                                {table?.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id} >
                                        {map(headerGroup.headers, header => {
                                            return (
                                                <Th h={10} left={0} key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </Th>
                                            )
                                        })}
                                        <Th>Action</Th>
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows && table.getRowModel().rows?.length ?
                                    table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.original?.id} color='gray.500'>
                                                {row.getVisibleCells().map(cell => {
                                                    return (
                                                        <Td borderRightColor="gray.100" bg="white" key={cell.id} left={0} maxW={200} overflowWrap="break-word">
                                                            <Box>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </Box>
                                                        </Td>
                                                    )
                                                })}
                                                <Td>
                                                    <Menu>
                                                        <MenuButton as={IconButton} icon={<BiDotsVerticalRounded />} variant='flushed' />
                                                        <MenuList>
                                                            <MenuItem icon={<EditIcon />} color='blue.500' onClick={() => handleTopperDrawer(row.original._id)}>Edit</MenuItem>
                                                            <MenuItem icon={<DeleteIcon />} color='red.500' onClick={() => toggleDeleteAlert(row.original._id)} >Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            </Tr>
                                        )
                                    })

                                    :

                                    <Tr >
                                        <Td colSpan={6}>
                                            <EmptyBox title='no toppers found'
                                                desc="Add Toppers"
                                            />
                                        </Td>
                                    </Tr>
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </LoadingContainer>
                {isOpen && <TopperDrawer topperId={topperId} isOpen={isOpen} onClose={onClose} />}
                {showDeleteAlert &&
                    <ConfirmAlert
                        isOpen={true}
                        onClose={() => toggleDeleteAlert(null)}
                        message="Are you sure you want to delete topper?"
                        heading='delete topper'
                        onConfirm={handleDeleteTopper}
                        loading={deleteTopperStatus === STATUS.FETCHING} />
                }
            </Box>
        </MainLayout>
    )
}