import { filter, map } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useAdmissionEnquiryStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
        })
    },

    getAdmissionEnquiryAction: async (payload) => {
        set({ getAdmissionEnquiryStatus: STATUS.FETCHING })
        const response = await apis.getAdmissionEnquiryApi(payload)
        if (response.ok) {
            set({ admissionEnquiry: response.data, getAdmissionEnquiryStatus: STATUS.SUCCESS })
        }
        else {
            set({ getAdmissionEnquiryStatus: STATUS.FAILED })
        }
    },

}))
