import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
  } from '@chakra-ui/react';
  import React, { useRef } from 'react';
  
  export const ConfirmAlert = ({
    isOpen,
    onClose,
    message,
    heading,
    onConfirm,
    loading,
    type = 'delete'
  }) => {
    const cancelRef = useRef();
  
    return (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
  
        <AlertDialogContent>
          <AlertDialogHeader>{heading}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} size='sm' onClick={onClose}>
              Cancel
            </Button>
            <Button
            size='sm'
              isLoading={loading}
              onClick={onConfirm}
              colorScheme={ type === 'info' ? 'blue' : type === 'success' ? 'green' : "red" }
              ml={3}
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };