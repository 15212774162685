import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    Box,
    Flex,
    VStack,
    Image,
    FormLabel,
} from '@chakra-ui/react'
import { useSchoolInformationStore } from '../../store/personalInformation';

export default function Profile({ isOpen, onClose }) {

    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))
    const { name, contact, username } = schoolData || {}

    return (
        <Modal size={{ base: 'full', lg: '2xl' }} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Box>
                        <Box mt={10}>
                            <VStack align='stretch' spacing={4} p={2}>
                                <Box>
                                    <FormLabel>
                                        User Name
                                    </FormLabel>
                                    <Box mt={-1} mb={4}>
                                        {username}
                                    </Box>
                                </Box>
                                <Box>
                                    <FormLabel>
                                        School Name
                                    </FormLabel>

                                    <Box mt={-1} mb={4}>
                                        {name}
                                    </Box>
                                </Box>
                                <Box>
                                    <FormLabel>
                                        Phone number
                                    </FormLabel>
                                    <Flex mt={2}>
                                        <Image w={10} alt='flag icon' mr={2} borderRadius={2} src='/assets/indiaFlag.webp' />
                                        <Box color='gray.600'>+91 {contact}</Box>
                                    </Flex>
                                </Box>
                                <Box>
                                </Box>
                            </VStack>
                            <Box w='50%' p={2}>

                            </Box>
                        </Box>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button size='sm' onClose={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    )
}
