
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';
import { filter, map } from 'lodash';
import { URIS } from '../../services/apis';

export const useGalleryStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addGalleryStatus: STATUS.NOT_STARTED,
            updateGalleryStatus: STATUS.NOT_STARTED,
            deleteGalleryStatus: STATUS.NOT_STARTED,
        })
    },

    addGalleryAction: async (payload) => {
        set({ addGalleryStatus: STATUS.FETCHING })
        const response = await apis.createGalleryApi(payload)
        const previousGalleryData = get().gallery || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ gallery: ([...previousGalleryData, (response.data)]), album: response.data, addGalleryStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addGalleryStatus: STATUS.FAILED })
        }
    },

    updateGalleryAction: async (payload) => {
        set({ updateGalleryStatus: STATUS.FETCHING })
        const response = await apis.updateGalleryApi(payload)
        const previosData = get().gallery
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ gallery: map(previosData, d => response.data._id === d._id ? response.data : d), updateGalleryStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateGalleryStatus: STATUS.FAILED })
        }
    },
    getGalleryAction: async (payload) => {
        set({ getGalleryStatus: STATUS.FETCHING })
        const response = await apis.getGalleryApi(payload)
        if (response.ok) {
            set({ gallery: response.data, getGalleryStatus: STATUS.SUCCESS })
        }
        else {
            set({ getGalleryStatus: STATUS.FAILED })
        }
    },

    deleteGalleryAction: async (payload) => {
        set({ deleteGalleryStatus: STATUS.FETCHING });
        const { ok } = await apis.deleteGalleryApi(payload)
        if (ok) {
            SuccessAlert('Deleted Successfully')
            let gallery = get().gallery;
            gallery = filter(gallery, d => d._id !== payload.id);
            set({ gallery: gallery, deleteGalleryStatus: STATUS.SUCCESS, })
        }
        else {
            ErrorAlert();
            set({ deleteGalleryStatus: STATUS.FAILED })
        }
    },

    /* Upload Images Functions */

    uploadImage: async (payload, type) => {
        set({ uploadImageStatus: STATUS.FETCHING, imageUploadType: type });
        const uploadUrl = URIS.UPLOAD_IMAGE;
        
        const { ok, data } = await apis.uploadImageApi(payload, uploadUrl)
        if (ok) {
            set({ uploadedImage: data, uploadImageStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert()
            set({ uploadImageStatus: STATUS.FAILED })
        }
    },
    
    resetUploadedImage: () => {
        set({ uploadImageStatus: STATUS.NOT_STARTED, uploadedImage: null, imageUploadType: null })
    },
    uploadMultipleImage: async (payload, type) => {
        set({ uploadMultipleImageStatus: STATUS.FETCHING, imageUploadType: type });
        const uploadUrl = URIS.UPLOAD_IMAGE;
        
        const { ok, data } = await apis.uploadMultipleImageApi(payload, uploadUrl)
        if (ok) {
            set({ uploadedMultipleImages: data, uploadMultipleImageStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert()
            set({ uploadMultipleImageStatus: STATUS.FAILED })
        }
    },
    
    resetUploadedMultipleImage: () => {
        set({ uploadMultipleImageStatus: STATUS.NOT_STARTED})
    },

    deleteImageAction: async (payload) => {
        set({ deleteImageStatus: STATUS.FETCHING });
        const { ok } = await apis.deleteImageApi(payload)
        if (ok) {
            let uploadedMultipleImages = get().uploadedMultipleImages;
            uploadedMultipleImages = filter(uploadedMultipleImages, d => d._id !== payload.id);
            set({ uploadedMultipleImages: uploadedMultipleImages, deleteImageStatus: STATUS.SUCCESS, })
        }
        else {
            ErrorAlert();
            set({ deleteImageStatus: STATUS.FAILED })
        }
    },

}))