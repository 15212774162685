import { useEffect, useState } from "react"
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"

import { UploadImage } from "../../components/UploadImage"

import { useSchoolInformationStore } from "../../store/personalInformation"
import { useStaffStore } from "../../store/staff"

import { STATUS } from "../../constants"
import { find } from "lodash"
import { SchoolId } from "../../config.js"

export const StaffDrawer = ({ isOpen, onClose, staffId }) => {
    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const { addStaffAction, addStaffStatus, updateStaffAction, updateStaffStatus, resetStatus, staff } = useStaffStore(s => ({
        addStaffAction: s.addStaffAction,
        addStaffStatus: s.addStaffStatus,
        updateStaffAction: s.updateStaffAction,
        updateStaffStatus: s.updateStaffStatus,
        resetStatus: s.resetStatus,
        staff: s.staff,
    }))
    
    const { control, handleSubmit, setValue, reset } = useForm()

    const [imageUrl, setImageUrl] = useState()

    const getImageUrl = (url) => {
        setImageUrl(url)
    }
    const onSubmit = (data) => {
        const { name, designation } = data || {};
        const staffData = {
            schoolId: SchoolId,
            name: name,
            designation: designation,
            avatar: imageUrl
        }
        if (staffId) {
            staffData['id'] = staffId;
            updateStaffAction(staffData);
        }
        else {
            addStaffAction(staffData);
        }
    }

    useEffect(() => {
        if ((addStaffStatus === STATUS.SUCCESS) || (updateStaffStatus === STATUS.SUCCESS)) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addStaffStatus, onClose, reset, resetStatus, updateStaffStatus])

    const particularStaff = find(staff, s => s._id === staffId)
    useEffect(() => {
        const { name, designation, avatar } = particularStaff || {};
        if (particularStaff) {
            setValue('name', name)
            setValue('designation', designation)
            setImageUrl(avatar)
        }
    }, [setValue, particularStaff])

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size='md'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`${staffId ? 'Update' : 'Add'} Staff`}</DrawerHeader>

                    <DrawerBody>
                        <Box >
                            <UploadImage title='Staff Image' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                            <FormControl isRequired mt={4}>
                                <FormLabel>Staff Name</FormLabel>
                                <Controller
                                    name='name'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} placeholder='Name' size='sm' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Designation</FormLabel>
                                <Controller
                                    name='designation'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} placeholder='Designation' size='sm' />}
                                />
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' size='sm' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={(addStaffStatus === STATUS.FETCHING) || (updateStaffStatus === STATUS.FETCHING)}
                            bg='#bd0745'
                            _hover={{ bg: '#a3053b' }}
                            color='white'
                            size='sm'
                            type='submit'
                        >
                            {staffId ? 'Update' : 'Add'}
                            </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    )
}