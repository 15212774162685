import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Input, Select, Textarea } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { MARKSTYPE, STATUS } from "../../constants"
import { useSchoolTopperStore } from "../../store/topper"
import { Controller, useForm } from "react-hook-form"
import { find, map } from "lodash"
import { UploadImage } from "../../components/UploadImage"
import { SchoolId } from "../../config.js"

export const TopperDrawer = ({ isOpen, onClose, topperId }) => {
    const [ imageUrl, setImageUrl ] = useState()
    const { control, handleSubmit, setValue, reset } = useForm()

    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    const { addTopperStatus, addTopperAction, updateTopperAction, updateTopperStatus, resetStatus, schoolTopperData } = useSchoolTopperStore(s => ({
        addTopperAction: s.addTopperAction,
        addTopperStatus: s.addTopperStatus,
        updateTopperAction: s.updateTopperAction,
        updateTopperStatus: s.updateTopperStatus,
        schoolTopperData: s.schoolTopperData,
        resetStatus: s.resetStatus
    }))

    const onSubmit = (data) => {
        const { name, marks, className, session, marksType } = data
        const toppersData = {
            schoolId: SchoolId,
            studentName: name,
            marks: marks,
            marksType: marksType,
            class: className,
            session: session,
            avatar: imageUrl
        }
        if (topperId) {
            toppersData['id'] = topperId;
            updateTopperAction(toppersData)
        }
        else {
            addTopperAction(toppersData)
        }
    }

    useEffect(() => {
        if ((updateTopperStatus === STATUS.SUCCESS) || (addTopperStatus === STATUS.SUCCESS)) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addTopperStatus, onClose, reset, resetStatus, updateTopperStatus])

    useEffect(() => {
        const findTopper = find(schoolTopperData, tData => tData._id === topperId)
        if (topperId) {
            const { studentName, designation, session, marks, marksType, avatar } = findTopper;
            setValue('name', studentName)
            setValue('designation', designation)
            setValue('session', session)
            setValue('className', findTopper?.class)
            setValue('marks', marks)
            setValue('marksType', marksType)
            setImageUrl(avatar)
        }
    }, [topperId, schoolTopperData, setValue, setImageUrl])

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size='md'
            onClose={onClose}
        >
            <DrawerOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>{`${topperId ? 'Update' : 'Add'} Topper`}</DrawerHeader>

                    <DrawerBody>
                        <Box >
                        <UploadImage title='Topper Image' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                            <FormControl isRequired mt={4}>
                                <FormLabel>Student Name</FormLabel>
                                <Controller
                                    control={control}
                                    name='name'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='name' size='sm' />
                                    }
                                />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>Session</FormLabel>
                                <Controller
                                    control={control}
                                    name='session'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='ex. 2021-22' size='sm' />
                                    }
                                />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>Class</FormLabel>
                                <Controller
                                    control={control}
                                    name='className'
                                    render={({ field }) =>
                                        <Input {...field} placeholder='ex. XI' size='sm' />
                                    }
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Marks Type</FormLabel>
                                <Controller
                                    control={control}
                                    name='marksType'
                                    render={({ field }) =>
                                        <Select {...field} placeholder='Marks Type' size='sm'>
                                            {map(MARKSTYPE, (marksType, index) =>
                                                <option key={index} value={marksType.value}>{marksType.title}</option>
                                            )}
                                        </Select>
                                    }
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Marks/CGPA</FormLabel>
                                <Controller
                                    control={control}
                                    name='marks'
                                    render={({ field }) =>
                                        <Input {...field} type='number' size='sm' />
                                    }
                                />
                            </FormControl>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' size='sm' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button
                            isLoading={(updateTopperStatus === STATUS.FETCHING) || (addTopperStatus === STATUS.FETCHING)}
                            bg='#bd0745'
                            _hover={{ bg: '#a3053b' }}
                            color='white'
                            size='sm'
                            type='submit'>
                            {`${topperId ? 'Update' : 'Save'}`}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </form>
        </Drawer>
    )
}