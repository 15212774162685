import { Box, Button, Center, Flex, FormControl, FormLabel, Textarea } from "@chakra-ui/react"
import MainLayout from "../app/layouts/MainLayout"
import { useEffect, useState } from "react"
import PageHeading from "../components/PageHeading"
import { useSchoolInformationStore } from "../store/personalInformation"
import { Controller, useForm } from "react-hook-form"
import { STATUS } from "../constants"
import { UploadImage } from "../components/UploadImage"
import { SchoolId } from "../config.js"

export const About = () => {
    const { control, handleSubmit, setValue } = useForm()
    const { addSchoolInformationStatus, addSchoolInformationAction, updateSchoolInformationAction, updateSchoolInformationStatus, schoolData } = useSchoolInformationStore(s => ({
        addSchoolInformationAction: s.addSchoolInformationAction,
        addSchoolInformationStatus: s.addSchoolInformationStatus,
        updateSchoolInformationAction: s.updateSchoolInformationAction,
        updateSchoolInformationStatus: s.updateSchoolInformationStatus,
        schoolData: s.schoolData,
    }))

    const [ imageUrl, setImageUrl ] = useState()

    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    const onSubmit = (data) => {
        const { vision, mission, about, foundationDescription } = data
        const About = {
            vision: vision,
            mission: mission,
            about: about,
            foundationHistory: foundationDescription,
            aboutImage: imageUrl

        }
        if (schoolData && SchoolId) {
            About['id'] = SchoolId;
            updateSchoolInformationAction(About)
        }
        else {
            addSchoolInformationAction(About)
        }
    }

    useEffect(() => {
        if (schoolData) {
            const { mission, vision, foundationHistory, about, aboutImage } = schoolData || {};
            setValue('mission', mission)
            setValue('vision', vision)
            setValue('about', about)
            setValue('foundationDescription', foundationHistory)
            setImageUrl(aboutImage)
        }
    }, [schoolData, setValue, setImageUrl])

    return (
        <MainLayout>
            <PageHeading heading='About Us' desc='Fill school details and their history'/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Center>
                    <Box borderRadius='3px' border='1px solid' borderColor='#ccc' p={3} w='600px'>
                            <UploadImage title='About Image' extraFormData={[ { key: 'schoolId' , value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl}/>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Vision</FormLabel>
                            <Controller
                                control={control}
                                name='vision'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='Vision' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Mission</FormLabel>
                            <Controller
                                control={control}
                                name='mission'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='Mission' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>About</FormLabel>
                            <Controller
                                control={control}
                                name='about'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='About' size='sm' />
                                }
                            />
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Foundation History</FormLabel>
                            <Controller
                                control={control}
                                name='foundationDescription'
                                render={({ field }) =>
                                    <Textarea {...field} placeholder='Foundation History' size='sm' />
                                }
                            />
                        </FormControl>
                        <Flex justify='flex-end' my={4}>
                            <Button
                                isLoading={addSchoolInformationStatus === STATUS.FETCHING || updateSchoolInformationStatus === STATUS.FETCHING}
                                type='submit'
                                color='white'
                                bg='#bd0745'
                                _hover={{ bg: '#a3053b' }}
                                size='sm'>
                                {`${SchoolId ? 'Update' : 'Add'}`}
                            </Button>
                        </Flex>
                    </Box>
                </Center>
            </form>
        </MainLayout>
    )
}