import { Box, Button, Center, Flex, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react"
import { useGalleryStore } from "../../store/gallery"
import { STATUS } from "../../constants"
import { SmallCloseIcon } from "@chakra-ui/icons"
import { SchoolId } from "../../config"
import { filter, find, map } from "lodash"
import { useEffect, useRef } from "react"
import { LoadingContainer } from "../../components/LoadingContainer"

export const ImagesModal = ({ albumId, isOpenModal, onCloseModal }) => {
    const ref = useRef()

    const { updateGalleryAction, updateGalleryStatus, gallery, resetStatus } = useGalleryStore(s => ({
        updateGalleryAction: s.updateGalleryAction,
        updateGalleryStatus: s.updateGalleryStatus,
        resetStatus: s.resetStatus,
        gallery: s.gallery,
    }))

    const { uploadMultipleImage, uploadMultipleImageStatus, uploadedMultipleImages, resetUploadedMultipleImage } = useGalleryStore(s => ({
        uploadMultipleImage: s.uploadMultipleImage,
        uploadMultipleImageStatus: s.uploadMultipleImageStatus,
        uploadedMultipleImages: s.uploadedMultipleImages,
        resetUploadedMultipleImage: s.resetUploadedMultipleImage,

    }))

    const singleAlbumData = find(gallery, g => g._id === albumId)

    const handleFileUpload = (e) => {
        const formData = new FormData()
        Array.from(e.target.files).forEach((file) => {
            formData.append('files', file);
        })
        formData.append('schoolId', SchoolId)
        uploadMultipleImage(formData)
    }

    useEffect(() => {
        if (uploadMultipleImageStatus === STATUS.SUCCESS) {
            const albumImages = {
                id: albumId,
                images: map(uploadedMultipleImages, img => img.fileUrl)
            }
            updateGalleryAction(albumImages)
            resetUploadedMultipleImage()
        }
    }, [albumId, resetUploadedMultipleImage, updateGalleryAction, uploadMultipleImageStatus, uploadedMultipleImages])

    useEffect(() => {
        if (updateGalleryStatus === STATUS.SUCCESS) {
            resetStatus()
        }
    }, [onCloseModal, resetStatus, updateGalleryStatus])

    const removeImage = (url) => {
        if (singleAlbumData && singleAlbumData.images?.length) {
            const filteredImage = filter(singleAlbumData.images, imgUrl => imgUrl !== url);
            updateGalleryAction({
                id: albumId,
                images: filteredImage
            })
        }
    }

    const handleOpenFileWindow = () => {
        ref.current.click();
    }

    return (
        <Modal isOpen={isOpenModal} onClose={onCloseModal} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload Album Images</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <HStack>
                            <Box onClick={handleOpenFileWindow} cursor='pointer'>
                                <Button bg='#bd0745' _hover={{ bg: '#a3053b' }} color='white' size='sm'>Upload</Button>
                            </Box>
                            <Box display='none'><Input type='file' ref={ref} size='sm' accept="image/*" multiple onChange={handleFileUpload} /></Box>
                            <Text color='gray.600'>(you can select multiple images)</Text>
                        </HStack>

                        <LoadingContainer loading={updateGalleryStatus === STATUS.FETCHING}>
                            {(singleAlbumData && (singleAlbumData.images?.length > 0)) ?
                                <Flex wrap='wrap' my={3}>
                                    {map(singleAlbumData.images, (img, index) =>
                                        <Box m={2} pos='relative' key={index}>
                                            <Image src={img} alt='image' maxW='80px' h='fit-content' crossOrigin='anonymous' />
                                            <Center borderRadius={50} w={4} h={4} boxShadow='lg' top={-1} right={0} bg='white' pos='absolute' onClick={() => removeImage(img)}><SmallCloseIcon /></Center>
                                        </Box>
                                    )}
                                </Flex>
                                :
                                <Center>
                                    <Image src='assets/Image_not_available.png' maxW='450px' alt='Image Not Found' crossOrigin='anonymous' />
                                </Center>
                            }
                        </LoadingContainer>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}