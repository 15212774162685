import { Box, Center, Image } from '@chakra-ui/react'
import React from 'react'
import HeadingX from './HeadingX'

export default function EmptyBox(props) {
    const { desc, title } = props;

    return (
        <Box bg='white' borderRadius={10} py={{ base: 10, md: 14, lg: 20 }} px={{ base: 10, md: 14, lg: 8 }}>
            <Center>
                <Image alt='empty' w={{ lg: 220 }} src='/assets/emptyBox.avif'/>
            </Center>
            <Box textAlign='center' mt={4}>
                <HeadingX desc={ desc }>
                    { title || "nothing found" }
                </HeadingX>
            </Box>
        </Box>

    )
}
