
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';
import { filter, map } from 'lodash';

export const useStaffStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addStaffStatus: STATUS.NOT_STARTED,
            updateStaffStatus: STATUS.NOT_STARTED,
            deleteStaffStatus: STATUS.NOT_STARTED,
        })
    },

    addStaffAction: async (payload) => {
        set({ addStaffStatus: STATUS.FETCHING })
        const response = await apis.addStaffApi(payload)
        const previousStaffData = get().staff || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ staff: ([...previousStaffData, (response.data)]), addStaffStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addStaffStatus: STATUS.FAILED })
        }
    },

    updateStaffAction: async (payload) => {
        set({ updateStaffStatus: STATUS.FETCHING })
        const response = await apis.updateStaffApi(payload)
        const previosData = get().staff
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ staff: map(previosData, d => response.data._id === d._id ? response.data : d), updateStaffStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateStaffStatus: STATUS.FAILED })
        }
    },
    getStaffAction: async (payload) => {
        set({ getStaffStatus: STATUS.FETCHING })
        const response = await apis.getStaffApi(payload)
        if (response.ok) {
            set({ staff: response.data, getStaffStatus: STATUS.SUCCESS })
        }
        else {
            set({ getStaffStatus: STATUS.FAILED })
        }
    },

    deleteStaffAction: async (payload) => {
        set({ deleteStaffStatus: STATUS.FETCHING });
        const { ok } = await apis.deleteStaffApi(payload)
        if (ok) {
            SuccessAlert('Deleted Successfully')
            let staff = get().staff;
            staff = filter(staff, d => d._id !== payload.id);
            set({ staff: staff, deleteStaffStatus: STATUS.SUCCESS, })
        }
        else {
            ErrorAlert();
            set({ deleteStaffStatus: STATUS.FAILED })
        }
    },

}))