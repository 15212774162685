
import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../services/apis/apis';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';

export const useSchoolInformationStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
            addSchoolInformationStatus: STATUS.NOT_STARTED,
            updateSchoolInformationStatus: STATUS.NOT_STARTED,
            deleteSchoolInformationStatus: STATUS.NOT_STARTED,
            addCrousalStatus: STATUS.NOT_STARTED,
            updateCrousalStatus: STATUS.NOT_STARTED,
        })
    },

    addSchoolInformationAction: async (payload) => {
        set({ addSchoolInformationStatus: STATUS.FETCHING })
        const response = await apis.addSchoolInformationApi(payload)
        const allSchoolData = get().allSchoolData || []
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ schoolData: response.data, allSchoolData: ([...allSchoolData, (response.data)]), addSchoolInformationStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addSchoolInformationStatus: STATUS.FAILED })
        }
    },

    updateSchoolInformationAction: async (payload) => {
        set({ updateSchoolInformationStatus: STATUS.FETCHING })
        const response = await apis.updateSchoolInformationApi(payload)
        const previosData = get().schoolData
        SuccessAlert('Updated Successfully')
        if (response.ok) {
            set({ schoolData: (response.data._id === previosData._id) ? response.data : previosData, updateSchoolInformationStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateSchoolInformationStatus: STATUS.FAILED })
        }
    },
    getSchoolInformationAction: async (payload) => {
        set({ getSchoolInformationStatus: STATUS.FETCHING })
        const response = await apis.getSchoolInformationApi(payload)
        if (response.ok) {
            set({ schoolData: response.data, getSchoolInformationStatus: STATUS.SUCCESS })
        }
        else {
            set({ getSchoolInformationStatus: STATUS.FAILED })
        }
    },

    getStateAction: async (payload) => {
        set({ getStateStatus: STATUS.FETCHING })
        const response = await apis.getStateApi(payload)
        if (response.ok) {
            set({ states: response.data, getStateStatus: STATUS.SUCCESS })
        }
        else {
            set({ getStateStatus: STATUS.FAILED })
        }
    },

    addCrousalAction: async (payload) => {
        set({ addCrousalStatus: STATUS.FETCHING })
        const response = await apis.addCrousalApi(payload)
        if (response.ok) {
            SuccessAlert('Added Successfully')
            set({ crousal: response.data, addCrousalStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ addCrousalStatus: STATUS.FAILED })
        }
    },

    updateCrousalAction: async (payload) => {
        set({ updateCrousalStatus: STATUS.FETCHING })
        const response = await apis.updateCrousalApi(payload)
        const previosData = get().crousal
        SuccessAlert('Crousal Updated')
        if (response.ok) {
            set({ crousal: (response.data._id === previosData._id) ? response.data : previosData, updateCrousalStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert();
            set({ updateCrousalStatus: STATUS.FAILED })
        }
    },
    getCrousalAction: async (payload) => {
        set({ getCrousalStatus: STATUS.FETCHING })
        const response = await apis.getCrousalApi(payload)
        if (response.ok) {
            set({ crousal: response.data, getCrousalStatus: STATUS.SUCCESS })
        }
        else {
            set({ getCrousalStatus: STATUS.FAILED })
        }
    },

}))
