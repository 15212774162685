import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Center, HStack, Image, Input, Spinner, Text } from "@chakra-ui/react";
import { forEach } from "lodash";
import { STATUS } from "../constants";
import { useGalleryStore } from "../store/gallery";

export const UploadImage = ({ getImageUrl, defaultValue, title, extraFormData }) => {
    const ref = React.useRef();
    const [imageUrl, setImageUrl] = useState();
    const { uploadImage, resetUploadedImage, uploadedImage, uploadImageStatus } = useGalleryStore()

    const _imgHandler = (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        if (extraFormData?.length > 0) {
            forEach(extraFormData, (field) => {
                formData.append(field.key, field.value);
            })
        }
        uploadImage(formData);
    }

    useEffect(() => {
        setImageUrl(defaultValue ? defaultValue : '/assets/profile.webp')
    }, [defaultValue])

    const onSubmit = () => {
        if (uploadedImage?.fileUrl) {
            getImageUrl(uploadedImage?.fileUrl)
        }
        resetUploadedImage()
    }

    useEffect(() => {
        if (uploadImageStatus === STATUS.SUCCESS) {
            onSubmit()
        }
    }, [uploadImageStatus])

    const handleOpenFileWindow = () => {
        ref.current.click()
    }

    const handleImageError = (event) => {
        event.target.style.display = "none";
      };

      const handleRemoveImage = () => {
        setImageUrl()
        getImageUrl(null)
        resetUploadedImage()
    }
    return (
        <>

            <Box my={2}>
                <Text fontWeight={500}>{title}</Text>
                <HStack mt={2} align='center' spacing={4}>
                    {uploadImageStatus === STATUS.FETCHING ?
                        <Center minW={61}> <Spinner /> </Center>
                        :
                        <Image w='50px' h='50px' borderRadius='50%' src={imageUrl} size='md' onError={handleImageError} crossOrigin="anonymous"/>}
                    <Box onClick={handleOpenFileWindow} cursor='pointer'>
                        <Button bg='#bd0745'  _hover={{ bg: '#a3053b' }} color='white' size='xs'>{ imageUrl?.[0] !== '/' ? "Replace" : "Upload" }</Button>
                    </Box>
                    {
                             imageUrl?.[0] !== '/' &&
                            <Button bg='#bd0745' color='white' _hover={{ bg: '#a3053b' }} size='xs' onClick={handleRemoveImage}>Remove</Button>
                        }
                    <Box display='none'><Input type='file' accept="image/*" ref={ref} size='sm' name='avatar' onChange={_imgHandler} /></Box>
                </HStack>
            </Box>

        </>
    )
}
