import { create } from 'apisauce';
import { apiMonitor } from './monitor';
// import { LoadingRef } from "../../app/AppProvider";

export const BaseURL = "https://websiteapi.jobdekhoapp.com/api/r1";          

export const URIS = {
  ADD_SCHOOL_INFORMATION: '/school',
  GET_SCHOOL_INFORMATION: '/school',
  ADD_NEWS: '/news',
  GET_NEWS: '/news/all',
  ADD_TOPPER: '/topper',
  GET_TOPPER: '/topper/all',
  GET_FEEDBACK: '/feedback/all',
  GET_ADMISSION_ENQUIRY: '/admission-enquiry/all',
  ADD_FACILITY: '/facility',
  GET_FACILITY: '/facility/all',
  UPLOAD_IMAGE: '/app/file',
  UPLOAD_MULTIPLE_IMAGE: '/app/files',
  GET_STATES: '/app/states',
  ADD_STAFF: '/staff',
  GET_STAFF: '/staff/all',
  ADD_CROUSAL: '/crousal',
  GET_CROUSAL: '/crousal/school',
  ADD_EVENT: '/event',
  GET_EVENT: '/event/all',
  LOGIN: '/auth/login',
  GALLERY: '/gallery',
  GET_GALLERY: 'gallery/all',
  DASHBOARD_COUNT: 'app/dashboard'
};

// export const getBaseUrl = () => {
//   return typeof window != 'undefined' &&
//     window.location.hostname.includes('betalite.schoollog.in')
//     ? 'https://api.stage.lite.schoollog.in/api/v1/'
//     : BaseURL;
// };

let api = create({
  baseURL: BaseURL,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  },
  timeout: 45000,
});

api.addMonitor(apiMonitor);

api.axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let originalRequest = error.config;
    let isunauth = error.response && error.response.status === 401;
    if (
      isunauth &&
      !originalRequest._retry &&
      !originalRequest.headers._retry
    ) {
      originalRequest._retry = true;
      //   LoadingRef.current.logoutForce();

      //get refresh token
      // const credentials = undefined // await getLoginCredentials();

      // if (credentials) {
      //   // const {refresh_token} = credentials;
      //   // // api call for access token using refresh token

      //   // return new Promise(async (resolve, reject) => {
      //   //   const response = await api.post(
      //   //     URIS.REFRESH,
      //   //     {refresh_token},
      //   //     {headers: {_retry: true}},
      //   //   );
      //   //   //store access_token and data
      //   //   if (response.ok && response.status == 200) {
      //   //     api.setHeader(
      //   //       'Authorization',
      //   //       'Bearer ' + response.data.access_token,
      //   //     );
      //   //     originalRequest.headers.Authorization =
      //   //       'Bearer ' + response.data.access_token;
      //   //     await setLoginCredentials(JSON.stringify(response.data));
      //   //     resolve(api.axiosInstance(originalRequest));
      //   //   } else {
      //   //     resetLoginCredentials().then(e => {
      //   //       store.dispatch({
      //   //         type: '@action.login.changeAppState',
      //   //         payload: APP_STATE.PUBLIC,
      //   //       });
      //   //     });
      //   //     Toast.show('You need to login again.');
      //   //     return resolve(true);
      //   //   }
      //   // });
      // } else {
      //   return Promise.resolve(error);
      // }
    } else {
      return Promise.resolve(error);
    }
  },
);

export const setAuthorizationHeader = (access_token) => {
  if (!access_token) delete api.headers['Authorization'];
  else api.setHeader('Authorization', 'Bearer ' + access_token);
};

export const setSchoolHeader = (id) => {
  console.log('idddd', id)
  if (!id) delete api.headers['schoolid'];
  else api.setHeader('schoolid', id);
};

export const removeAuthorizationHeader = () => {
  delete api.headers['Authorization'];
};

export const setUserAgent = (info) => api.setHeader('device', info);

export { api as apiClient };

//for swr fetcher
export const getFetcher = (url, params, config) =>
  api
    .get(url, params, config)
    .then((response) => {
      return response.data;
    })
    .catch((er) => {
      throw er;
    });

// Add a request interceptor
// api.axiosInstance.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     // NProgress.configure({showSpinner:true})
//     NProgress.start();
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     NProgress.done();
//     console.error(error);
//     // eslint-disable-next-line no-undef
//     return Promise.reject(error);
//   },
// );

// // Add a response interceptor
// api.axiosInstance.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     NProgress.done();
//     return response;
//   },
//   function (error) {
//     // Do something with response error
//     NProgress.done();
//     console.error(error);
//     // eslint-disable-next-line no-undef
//     return Promise.reject(error);
//   },
// );
