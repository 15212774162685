import { useEffect, useState } from "react"
import { Box, Button, Center, Flex } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import PageHeading from "../../components/PageHeading"
import { STATUS } from "../../constants"
import { useSchoolInformationStore } from "../../store/personalInformation"
import { UploadImage } from "../../components/UploadImage"
import { SchoolId } from "../../config.js"

export const CrousalImage = () => {
    const [imageUrl, setImageUrl] = useState()

    const { addCrousalAction, addCrousalStatus, updateCrousalAction, updateCrousalStatus, schoolData, crousal, getCrousalAction } = useSchoolInformationStore(s => ({
        addCrousalAction: s.addCrousalAction,
        addCrousalStatus: s.addCrousalStatus,
        updateCrousalAction: s.updateCrousalAction,
        updateCrousalStatus: s.updateCrousalStatus,
        schoolData: s.schoolData,
        crousal: s.crousal,
        getCrousalAction: s.getCrousalAction,
    }))


    const onSubmit = (e) => {
        e.preventDefault();
        const crousalData = {
            schoolId: SchoolId,
            image: imageUrl
        }

        if (crousal && crousal._id) {
            crousalData['id'] = crousal._id;
            updateCrousalAction(crousalData)
        }
        else {
            addCrousalAction(crousalData)
        }
    }


    const getImageUrl = (url) => {
        setImageUrl(url)
    }

    useEffect(() => {
        if (crousal) {
            setImageUrl(crousal.image)
        }
    }, [crousal])

    useEffect(() => {
        if (!crousal && schoolData) {
            getCrousalAction({ schoolId: SchoolId })
        }
    }, [getCrousalAction, crousal, schoolData])

    return (
        <MainLayout>
            <PageHeading heading='Carousel' desc='Add schools carousel images'/>
            <form onSubmit={onSubmit}>
                <Center>
                    <Box borderRadius='3px' border='1px solid' borderColor='#ccc' p={3} w='600px'>
                        <UploadImage title='Carousel Image' extraFormData={[{ key: 'schoolId', value: SchoolId }]} getImageUrl={(url) => getImageUrl(url)} defaultValue={imageUrl} />
                        <Flex justify='flex-end' my={4}>
                            <Button
                                isLoading={(addCrousalStatus === STATUS.FETCHING) || (updateCrousalStatus === STATUS.FETCHING)}
                                type='submit'
                                color='white'
                                bg='#bd0745'
                                _hover={{ bg: '#a3053b' }}
                                isDisabled={!imageUrl}
                                size='sm'>
                                {`${crousal?._id ? 'Update' : 'Add'}`}
                            </Button>
                        </Flex>
                    </Box>
                </Center>
            </form>
        </MainLayout>
    )
}