import { Box, Center, Flex, Heading, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Tooltip, VStack } from "@chakra-ui/react";
import { concat, find, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NavReference } from "./MainLayout";
import { LOCAL_STORAGE_KEYS } from "../../constants";

import { FaChevronDown } from "react-icons/fa";
import { MdEmojiPeople, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineEmojiEvents, MdOutlineFeedback, MdOutlineSpaceDashboard } from "react-icons/md";
import { AiOutlineCloseCircle, AiOutlineQuestionCircle, AiOutlineExclamationCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { BiMessageRounded, BiNews } from "react-icons/bi";
import { RiUserSettingsLine } from "react-icons/ri";
import { BsImages } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import { GiTeacher } from "react-icons/gi";
import { SlCalender } from "react-icons/sl"

const NavLinks = [
    {
        title: 'Dashboard',
        icon: <MdOutlineSpaceDashboard />,
        route: '/dashboard'
    },
    {
        title: 'Personal Information',
        icon: <CgProfile />,
        route: '/personal-information'
    },
    {
        title: 'Carousel',
        icon: <BsImages />,
        route: '/carousel'
    },
    {
        title: 'About',
        icon: <AiOutlineInfoCircle />,
        route: '/about-us'
    },
    {
        title: 'Staff',
        icon: <GiTeacher />,
        route: '/staff'
    },
    {
        title: 'Gallery',
        icon: <BsImages />,
        route: '/gallery'
    },
    {
        title: 'Facility',
        icon: <RiUserSettingsLine />,
        childern: [
            {
                title: 'Sports',
                route: '/sports'
            },
            {
                title: 'Lab',
                route: '/lab'
            },
            {
                title: 'Club',
                route: '/club'
            },
            {
                title: 'Hostel',
                route: '/hostel'
            },
            {
                title: 'Library',
                route: '/library'
            },
        ]
    },
    {
        title: 'News',
        icon: <BiNews />,
        route: '/news'
    },
    {
        title: 'Events',
        icon: <SlCalender />,
        route: '/events'
    },
    {
        title: 'Toppers',
        icon: <MdEmojiPeople />,
        route: '/toppers'
    },
    {
        title: 'Achivement',
        icon: <MdOutlineEmojiEvents />,
        route: '/achivement'
    },
    {
        title: 'Desk',
        icon: <BiMessageRounded />,
        route: '/desk'
    },
    {
        title: 'FeedBack',
        icon: <MdOutlineFeedback />,
        route: '/feedback-list'
    },
    {
        title: 'Complain',
        icon: <AiOutlineExclamationCircle />,
        route: '/complain-list'
    },
    {
        title: 'Enquiry',
        icon: <AiOutlineQuestionCircle />,
        route: '/Enquiry-list'
    },

]

export default function Sidebar({ showSidebarMenu, toggleSidebarMenu, showExtraSidear }) {
    const [sidebarHeight, setSidebarHeight] = useState();

    const localShowSidebar = localStorage.getItem(LOCAL_STORAGE_KEYS.showSidebarOpen);
    const defaultShowOpen = ((localShowSidebar === 'true') || (localShowSidebar === null)) ? true : false;
    const [showSidebarItemDetail, setShowSidebarItemDetail] = useState(defaultShowOpen);

    const toggleShowSideabarDetail = () => {
        setShowSidebarItemDetail(p => !p)
        localStorage.setItem(LOCAL_STORAGE_KEYS.showSidebarOpen, !showSidebarItemDetail)
    }

    const updateHeight = useCallback(() => {
        const headerElement = NavReference?.current?.getClientRects();
        if (headerElement?.length > 0) {
            setSidebarHeight('calc( 100vh - ' + headerElement[0].height + 'px )');
        }
    }, [])

    useEffect(() => {
        const listener = () => {
            updateHeight();
        };
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        }
    }, [updateHeight])

    useEffect(() => {
        updateHeight();
    }, [updateHeight])

    return (
        <Flex w={{ base: '100%', lg: 'auto' }} transition={'left 0.3s'} bg='transparent' minH={{ base: '100vh', lg: 'auto' }} zIndex={9} pos={{ base: 'fixed', lg: 'relative' }} top='0px' left={{ base: showSidebarMenu ? '0px' : '-100%', lg: 0 }}>
            <Box borderRight={showExtraSidear && '1px solid'} borderColor='gray.200' transition={'width 0.3s'} pos='relative' boxShadow={{ base: 'lg', lg: 'none' }} h='100%' py={{ base: 4, lg: 0 }} w={{ base: '70%', lg: showSidebarItemDetail ? '250px' : '80px' }}>
                <Box w='100%' px={4} justifyContent={'flex-end'} display={{ base: 'flex', lg: 'none' }}>
                    <IconButton size='sm' onClick={toggleSidebarMenu}>
                        <AiOutlineCloseCircle />
                    </IconButton>
                </Box>
                <VStack justify='space-between' mt={{ base: 4, lg: 0 }} h={{ base: '100vh', lg: sidebarHeight }} className="scrollbar-1" overflowY={'scroll'} pb={{ base: 20, lg: 4 }} pt={{ lg: 4 }}>
                    <Box w='100%'>
                        {
                            map(concat(NavLinks), (item, index) => <SidebarItem key={index} details={item} showSidebarItemDetail={showSidebarItemDetail} />)
                        }
                    </Box>
                </VStack>

                <Center display={{ base: 'none', lg: 'flex' }} border='2px solid' borderColor={'gray.100'} color='white' cursor='pointer' onClick={toggleShowSideabarDetail} pos='absolute' bottom='2%' transform={'translate(-50%)'} borderRadius={'50%'} left={'100%'} bg='#bd0745' p={1} fontSize={15} boxShadow='sm'>
                    {
                        showSidebarItemDetail ?
                            <MdKeyboardArrowLeft fontSize='16px' />
                            :
                            <MdKeyboardArrowRight fontSize='16px' />
                    }
                </Center>
            </Box>
            <Box h={{ base: '100vh', lg: 0 }} flexGrow={1} onClick={toggleSidebarMenu} w={{ base: '30%', lg: 0 }} transition='width 0s 0.3s'>
            </Box>
        </Flex>
    )
}
const SidebarItem = ({ details, isChildren, showSidebarItemDetail }) => {
    const location = useLocation();
    const [showChildrens, setShowChildrens] = useState(false);

    const isActive = useMemo(() => {
        return (location.pathname === details.route) || find(details.childern, d => d.route === location.pathname) !== undefined;
    }, [location, details])

    const toggleChilrens = () => {
        setShowChildrens(p => !p)
    }

    useEffect(() => {
        setShowChildrens(isActive);
    }, [isActive])

    return (
        <Box px={{ base: 8, lg: 2 }}
            bg={isActive && !showSidebarItemDetail && !isChildren && '#bd0745'}
            borderTopRightRadius={isActive && !showSidebarItemDetail && 50}
            borderBottomRightRadius={isActive && !showSidebarItemDetail && 50}
            _hover={{ bg: !isChildren && !isActive && !'gray.50' }} py={isChildren ? 1 : 2} w='full'
            borderBottom={!isChildren && '0px solid'} borderColor={isActive ? 'defaultColor.400' : 'gray.100'}
        >
            <Box px={{ lg: 2 }}>
                <SidebarItemDetailContainer heading={details.title} hasChildren={details.childern} showChildrens={showChildrens} showSidebarItemDetail={showSidebarItemDetail} popoverTarget={
                    <>
                        {
                            details.childern ?
                                <Box onClick={toggleChilrens} >
                                    <NavItemsDetails isChildren={isChildren} showSidebarItemDetail={showSidebarItemDetail} childVisible={showChildrens} hasChildren={true} details={details} isActive={isActive} />
                                </Box>
                                : details.route ?
                                    <Link to={details.route}>
                                        <NavItemsDetails isChildren={isChildren} showSidebarItemDetail={showSidebarItemDetail} childVisible={isActive} hasChildren={false} details={details} isActive={isActive} />
                                    </Link>
                                    : details.onClick ?
                                        <Box onClick={details.onClick}>
                                            <NavItemsDetails isChildren={isChildren} showSidebarItemDetail={showSidebarItemDetail} childVisible={isActive} details={details} hasChildren={false} isActive={isActive} />
                                        </Box>
                                        : null
                        }
                    </>
                }>
                    <VStack borderLeft={showSidebarItemDetail && '3px solid'} borderColor='gray.400' ml={4} pl={showSidebarItemDetail && 4} transition='all 0.1s' mt={showChildrens && showSidebarItemDetail ? 3 : 0} align='start'>
                        {
                            map(details.childern, (childNav, i) => <SidebarItem key={i} showSidebarItemDetail={showSidebarItemDetail} isChildren={true} details={childNav} />)
                        }
                    </VStack>
                </SidebarItemDetailContainer>
            </Box>
        </Box>
    )
}

const SidebarItemDetailContainer = (props) => {
    const { popoverTarget, showSidebarItemDetail, showChildrens, hasChildren, heading } = props;
    return (
        !showSidebarItemDetail && hasChildren ?
            <Popover closeDelay={400} openDelay={-100} size='sm' trigger='hover' placement='right'>
                <PopoverTrigger>
                    <Box>
                        {
                            popoverTarget
                        }
                    </Box>
                </PopoverTrigger>
                <PopoverContent boxShadow={'lg'} maxW={200} borderRadius={10}>
                    <PopoverHeader bg='gray.700' color='white' borderTopLeftRadius={5} borderTopRightRadius={5} >
                        <Heading pl={4} fontSize='md'>{heading}</Heading>
                    </PopoverHeader>
                    <PopoverArrow />
                    <PopoverBody px={0} py={2}>
                        {
                            props.children
                        }
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            :
            <>
                {popoverTarget}
                {hasChildren && showChildrens && props.children}
            </>
    )
}

const NavItemsDetails = ({ details, isActive, hasChildren, childVisible, showSidebarItemDetail, isChildren }) => {
    const { title, icon } = details;
    return (
        <Flex role='group' cursor={'pointer'} align={'center'} justify={'space-between'}>
            <Tooltip label={!showSidebarItemDetail && !isChildren && !hasChildren && title} placement='right' hasArrow>
                <Flex align='center'>
                    {
                        icon &&
                        <Box pos='relative' boxShadow='lg' border='1px solid' borderColor={'gray.200'} p={2} bg={isActive ? '#bd0745' : 'white'} color={isActive ? 'white' : 'gray.700'} fontSize={18} borderRadius={50} mr={4}>
                            {icon}
                        </Box>
                    }
                    {
                        (showSidebarItemDetail || !showSidebarItemDetail && isChildren) &&
                        <Box fontSize={'sm'} wordBreak='keep-all' whiteSpace='nowrap' overflow='hidden' transition='all 0s 0.3s' color={isActive ? '#bd0745' : 'black'} fontWeight={'bold'}>
                            {title}
                        </Box>
                    }
                </Flex>
            </Tooltip>
            {
                hasChildren && showSidebarItemDetail &&
                <Box color={childVisible ? 'defaultcolor.400' : 'gray.400'} transform={!childVisible && "rotateZ(-90deg)"} transition='all 0.3s' fontSize={'sm'}>
                    <FaChevronDown />
                </Box>
            }
        </Flex>
    )
}
